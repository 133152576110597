import React from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

const columns = [
	{
		title: 'Boyut (mm)',
		dataIndex: 'boyut',
		key: 'boyut',
	},
	{
		title: 'Ağırlık (kg/m)',
		dataIndex: 'agirlik',
		key: 'agirlik',
	},
];

const dataLama = [
	{
		key: '1',
		boyut: '20x10',
		agirlik: '1.57',
	},
	{
		key: '2',
		boyut: '25x10',
		agirlik: '1.9625',
	},
	{
		key: '3',
		boyut: '25x10',
		agirlik: '1.9625',
	},
	{
		key: '4',
		boyut: '35x10',
		agirlik: '2.7475',
	},
	{
		key: '5',
		boyut: '40x10',
		agirlik: '3.14',
	},
	{
		key: '6',
		boyut: '50x10',
		agirlik: '3.925',
	},
	{
		key: '7',
		boyut: '50x15',
		agirlik: '5.8875',
	},
	{
		key: '8',
		boyut: '50x20',
		agirlik: '7.85',
	},
	{
		key: '9',
		boyut: '60x10',
		agirlik: '4.71',
	},
	{
		key: '10',
		boyut: '70x10',
		agirlik: '5.495',
	},
	{
		key: '11',
		boyut: '80x10',
		agirlik: '6.28',
	},
	{
		key: '12',
		boyut: '100x10',
		agirlik: '7.85',
	},
];

const HaddeLama: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div id="tablo-kare" className="mx-4 p-4 my-4">
			<h1 className="text-xl font-bold text-center">
				Hadde grubu agirlik tablolari
			</h1>

			<div className="flex items-center justify-evenly my-4 flex-wrap">
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-kare')}
					className="my-1 w-40"
				>
					Kare Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-kosebent')}
					className="my-1 w-40"
				>
					Kosebent Tablosu
				</Button>
				<Button
					type="primary"
					size="large"
					onClick={() => navigate('/hadde-lama')}
					className="my-1 w-40"
				>
					Lama Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npi')}
					className="my-1 w-40"
				>
					NPI Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npu')}
					className="my-1 w-40"
				>
					NPU Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-silme')}
					className="my-1 w-40"
				>
					Silme Tablosu
				</Button>
			</div>
			<Table
				columns={columns}
				dataSource={dataLama}
				bordered
				pagination={false}
				title={() => {
					return (
						<h1 className="text-xl font-bold text-center">Lama Tablosu</h1>
					);
				}}
			/>
		</div>
	);
};

export default HaddeLama;

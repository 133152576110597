import React from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

const columns = [
	{
		title: 'Boyut (mm)',
		dataIndex: 'boyut',
		key: 'boyut',
	},
	{
		title: 'Ağırlık (kg/m)',
		dataIndex: 'agirlik',
		key: 'agirlik',
	},
];

const dataSilme = [
	{
		key: '1',
		boyut: '16x3',
		agirlik: '0.3768',
	},
	{
		key: '2',
		boyut: '20x3',
		agirlik: '0.471',
	},
	{
		key: '3',
		boyut: '20x5',
		agirlik: '0.785',
	},
	{
		key: '4',
		boyut: '25x5',
		agirlik: '0.9813',
	},
	{
		key: '5',
		boyut: '30x3',
		agirlik: '0.7065',
	},
	{
		key: '6',
		boyut: '30x5',
		agirlik: '1.1775',
	},
	{
		key: '7',
		boyut: '40x5',
		agirlik: '1.57',
	},
	{
		key: '8',
		boyut: '50x5',
		agirlik: '1.9625',
	},
];

const HaddeSilme: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div id="tablo-kare" className="mx-4 p-4 my-4">
			<h1 className="text-xl font-bold text-center">
				Hadde grubu agirlik tablolari
			</h1>

			<div className="flex items-center justify-evenly my-4 flex-wrap">
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-kare')}
					className="my-1 w-40"
				>
					Kare Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-kosebent')}
					className="my-1 w-40"
				>
					Kosebent Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-lama')}
					className="my-1 w-40"
				>
					Lama Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npi')}
					className="my-1 w-40"
				>
					NPI Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npu')}
					className="my-1 w-40"
				>
					NPU Tablosu
				</Button>
				<Button
					type="primary"
					size="large"
					onClick={() => navigate('/hadde-silme')}
					className="my-1 w-40"
				>
					Silme Tablosu
				</Button>
			</div>
			<Table
				columns={columns}
				dataSource={dataSilme}
				bordered
				pagination={false}
				title={() => {
					return (
						<h1 className="text-xl font-bold text-center">Silme Tablosu</h1>
					);
				}}
			/>
		</div>
	);
};

export default HaddeSilme;

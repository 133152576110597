import React from 'react';
import { Card } from 'antd';
import SacDetay from '../images/sac-detay.png';
import DKPImage from '../images/dkp-card.jpg';
import HRPImage from '../images/hrp-card.jpeg';
import GalvanizliImage from '../images/galvanizli-card.jpg';
import BaklavaliImage from '../images/baklavali-card.jpg';
import GozYasiDesenliImage from '../images/gozyasi-desenli-card.jpg';
import SiyahImage from '../images/siyah-card.png';
import BaskiliImage from '../images/baskili-card.jpg';
import '../index.css';

const SacGrubu: React.FC = () => {
	const { Meta } = Card;
	const paragraphStyle = 'my-4 text-xl indent-8';
	const subTitleStyle = 'text-2xl font-bold text-center mb-4';
	const cardStyle = 'w-full md:w-2/5 lg:w-1/5 mb-12';
	const titleStyle = 'text-2xl font-bold text-center mb-8';

	const DKPSacProfileDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				DKP sac, soğuk haddelenmiş, düz ve parlak yüzeyli saclardır. Yüksek
				çekme mukavemeti ve mükemmel yüzey kalitesi ile bilinir.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Yüksek çekme mukavemeti ile dayanıklıdır.
				</li>
				<li>
					<b>Mükemmel Yüzey Kalitesi</b> Pürüzsüz ve parlak yüzeyi sayesinde
					estetik bir görünüm sunar.
				</li>
				<li>
					<b>İyi İşlenebilirlik:</b> Kolay şekillendirilebilir ve
					kaynaklanabilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Otomotiv sektörü</li>
				<li>Beyaz eşya üretimi</li>
				<li>Elektronik sanayi</li>
			</ul>
		</div>
	);
	const HRPSacProfileDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				HRP sac, sıcak haddelenmiş ve yüzeyi mat olan saclardır. Yüksek
				mukavemeti ve geniş kullanım alanları ile öne çıkar.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Sıcak haddelenmiş yapısı sayesinde
					dayanıklıdır.
				</li>
				<li>
					<b>Geniş Kullanım Alanı:</b> Çeşitli endüstriyel uygulamalarda
					kullanılır.
				</li>
				<li>
					<b>Ekonomik:</b> Maliyet açısından avantajlıdır.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İnşaat sektörü</li>
				<li>Makine imalatı</li>
				<li>Boru üretimi</li>
			</ul>
		</div>
	);
	const GalvanizliSacProfileDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Galvanizli saclar, çinko kaplama ile korunan saclardır. Korozyona karşı
				yüksek direnç gösterirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Korozyon Direnci:</b> Çinko kaplama sayesinde uzun ömürlüdür.
				</li>
				<li>
					<b>Estetik Görünüm:</b> Parlak ve düzgün yüzeyi estetik bir görünüm
					sunar.
				</li>
				<li>
					<b>Çevre Dostu:</b> Geri dönüştürülebilir malzemeden üretilmiştir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Çatı kaplamaları</li>
				<li>Beyaz eşya üretimi</li>
				<li>Elektrik panoları</li>
			</ul>
		</div>
	);
	const BaklavaliSacProfileDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Baklavalı saclar, yüzeyinde baklava desenleri bulunan kaymaz saclardır.
				Güvenlik ve estetik açısından tercih edilir.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Kaymaz Yüzey:</b> Desenli yüzeyi sayesinde kaymayı önler.
				</li>
				<li>
					<b>Dayanıklılık:</b> Aşınmaya karşı dirençlidir.
				</li>
				<li>
					<b>Estetik:</b> Görsel olarak çekici bir tasarıma sahiptir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Merdiven basamakları</li>
				<li>Yük platformları</li>
				<li>Araç zemin kaplamaları</li>
			</ul>
		</div>
	);
	const GozyasiDesenliSacProfileDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Gözyaşı desenli saclar, yüzeyinde gözyaşı desenleri bulunan kaymaz
				saclardır. Güvenlik amacıyla kullanılır.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Kaymaz Yüzey:</b> Desenli yüzeyi sayesinde kaymayı önler.
				</li>
				<li>
					<b>Dayanıklılık:</b> Aşınmaya karşı dirençlidir.
				</li>
				<li>
					<b>Estetik:</b> Görsel olarak çekici bir tasarıma sahiptir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Zemin kaplamaları</li>
				<li>Merdiven basamakları</li>
				<li>Endüstriyel platformlar</li>
			</ul>
		</div>
	);
	const SiyahSacProfileDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Siyah sac, sıcak haddelenmiş ve yüzeyi siyah oksit kaplı saclardır.
				Genel amaçlı kullanıma uygundur.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Dayanıklı ve sağlam bir yapıya sahiptir.
				</li>
				<li>
					<b>Geniş Kullanım Alanı:</b> Birçok endüstriyel uygulamada
					kullanılabilir.
				</li>
				<li>
					<b>Ekonomik:</b> Uygun maliyetlidir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İnşaat sektörü</li>
				<li>Makine imalatı</li>
				<li>Sanayi boruları</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 40 mm x 20 mm'den 400 mm x 100 mm'ye kadar
				</li>
				<li>
					<b>Kalınlık:</b> 4 mm - 12 mm
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>
		</div>
	);
	const BaskiliSacProfileDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Baskılı saclar, çeşitli desenlerle baskı yapılmış saclardır. Dekoratif
				ve işlevsel amaçlarla kullanılır.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Estetik Görünüm:</b> Çeşitli desenlerle estetik bir görünüm sunar.
				</li>
				<li>
					<b>Dayanıklılık:</b> Aşınmaya ve darbelere karşı dirençlidir.
				</li>
				<li>
					<b>Çeşitli Renk ve Desenler:</b> Farklı ihtiyaçlara göre çeşitli
					seçenekler sunar.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Dekorasyon</li>
				<li>Mobilya üretimi</li>
				<li>Reklam panoları</li>
			</ul>
		</div>
	);

	return (
		<div className="m-8">
			<h1 className="text-3xl font-bold text-center mb-4">Sac Grubu</h1>
			<section className="flex flex-row flex-nowrap my-4">
				<div
					id="sac-paragraphs"
					className="mx-auto w-auto p-4 flex flex-col-reverse md:flex-row lg:flex-row justify-center items-center"
				>
					<div>
						<p className={paragraphStyle}>
							Sac grubu ürünlerimiz, İzmir'deki demir çelik sektöründe geniş bir
							kullanım alanına sahip olan yüksek kaliteli malzemelerden oluşur.
							Firmamız, DKP sac, HRP sac, galvanizli sac, baklavalı sac, gözyaşı
							desenli sac, siyah sac ve baskılı sac gibi çeşitli sac türlerini
							müşterilerimize sunmaktadır. Her biri farklı özelliklere sahip
							olan bu ürünler, inşaat, otomotiv, beyaz eşya ve endüstriyel
							uygulamalarda kullanılmak üzere tasarlanmıştır.
						</p>
						<p className={paragraphStyle}>
							Sac grubu ürünlerimiz, yüksek mukavemet, dayanıklılık ve estetik
							görünüm sunarak projelerinizin ihtiyaçlarına en uygun çözümleri
							sağlar. Ürünlerimiz hakkında daha fazla bilgi edinmek ve
							projeleriniz için en doğru sac türünü seçmek için ilgili sayfaları
							inceleyebilir veya bizimle iletişime geçebilirsiniz.
						</p>
					</div>
					<img
						src={SacDetay}
						className="w-full md:w-1/2 lg:w-2/5 h-full md:h-1/2 lg:h-2/3 my-auto rounded-md shadow-xl"
						alt="Sac"
					/>
				</div>
			</section>
			<h2 className={titleStyle}>Sac Grubu Ürünleri</h2>
			<div
				id="sac-cards"
				className="flex flex-row flex-wrap justify-around gap-4 my-4"
			>
				<Card className={cardStyle} cover={<img alt="DKP" src={DKPImage} />}>
					<Meta
						title={<h2 className={subTitleStyle}>DKP Sac</h2>}
						description={DKPSacProfileDesc}
					/>
				</Card>
				<Card className={cardStyle} cover={<img alt="HRP" src={HRPImage} />}>
					<Meta
						title={<h2 className={subTitleStyle}>HRP Sac</h2>}
						description={HRPSacProfileDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Galvanizli Sac" src={GalvanizliImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Galvanizli Sac</h2>}
						description={GalvanizliSacProfileDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Baklavalı Sac" src={BaklavaliImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Baklavalı Sac</h2>}
						description={BaklavaliSacProfileDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Gözyaşı Desenli Sac" src={GozYasiDesenliImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Gözyaşı Desenli Sac</h2>}
						description={GozyasiDesenliSacProfileDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Siyah Sac" src={SiyahImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Siyah Sac</h2>}
						description={SiyahSacProfileDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Baskılı Sac" src={BaskiliImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Baskılı Sac</h2>}
						description={BaskiliSacProfileDesc}
					/>
				</Card>
			</div>
		</div>
	);
};

export default SacGrubu;

import React, { useEffect } from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

const columns = [
	{
		title: 'Ebat (mm) - Kalınlık (mm)',
		dataIndex: 'b1',
		key: 'b1',
	},
	{
		title: '1 Boy Ağırlık / 6 Metre (kg)',
		dataIndex: 'a1',
		key: 'a1',
	},
	{
		title: 'Ebat (mm) - Kalınlık (mm)',
		dataIndex: 'b2',
		key: 'b2',
	},
	{
		title: '1 Boy Ağırlık / 6 Metre (kg)',
		dataIndex: 'a2',
		key: 'a2',
	},
];

const data = [
	{
		b1: '20X20X1,50MM',
		a1: '5,61KG',
		s: '',
		b2: '90X90X2,00MM',
		a2: '33,69KG',
	},
	{
		b1: '20X20X2,00MM',
		a1: '7,48KG',
		s: '',
		b2: '90X90X2,50MM',
		a2: '42,12KG',
	},
	{
		b1: '20X20X2,50MM',
		a1: '9,50KG',
		s: '',
		b2: '90X90X3,00MM',
		a2: '50,54KG',
	},
	{
		b1: '25X25X1,50MM',
		a1: '7,02KG',
		s: '',
		b2: '90X90X4,00MM',
		a2: '67,39KG',
	},
	{
		b1: '25X25X2,00MM',
		a1: '9,36KG',
		s: '',
		b2: '90X90X5,00MM',
		a2: '84,24KG',
	},
	{
		b1: '25X25X2,50MM',
		a1: '11,80KG',
		s: '',
		b2: '90X90X6,00MM',
		a2: '94,73KG',
	},
	{
		b1: '25X25X3,00MM',
		a1: '12,41KG',
		s: '',
		b2: '100X100X2,00MM',
		a2: '37,44KG',
	},
	{
		b1: '30X30X1,50MM',
		a1: '8,42KG',
		s: '',
		b2: '100X100X2,50MM',
		a2: '46,80KG',
	},
	{
		b1: '30X30X2,00MM',
		a1: '10,56KG',
		s: '',
		b2: '100X100X3,00MM',
		a2: '56,16KG',
	},
	{
		b1: '30X30X2,50MM',
		a1: '14,04KG',
		s: '',
		b2: '100X100X4,00MM',
		a2: '74,88KG',
	},
	{
		b1: '30X30X3,00MM',
		a1: '16,84KG',
		s: '',
		b2: '100X100X5,00MM',
		a2: '93,60KG',
	},
	{
		b1: '40X40X1,50MM',
		a1: '11,23KG',
		s: '',
		b2: '100X100X6,00MM',
		a2: '106,01KG',
	},
	{
		b1: '40X40X2,00MM',
		a1: '14,96KG',
		s: '',
		b2: '100X100X8,00MM',
		a2: '138,04KG',
	},
	{
		b1: '40X40X2,50MM',
		a1: '18,72KG',
		s: '',
		b2: '110X110X3,00MM',
		a2: '60,34KG',
	},
	{
		b1: '40X40X3,00MM',
		a1: '22,46KG',
		s: '',
		b2: '110X110X4,00MM',
		a2: '79,70KG',
	},
	{
		b1: '40X40X4,00MM',
		a1: '29,95KG',
		s: '',
		b2: '110X110X5,00MM',
		a2: '98,68KG',
	},
	{
		b1: '40X40X5,00MM',
		a1: '27,07KG',
		s: '',
		b2: '110X110X6,00MM',
		a2: '117,29KG',
	},
	{
		b1: '50X50X1,50MM',
		a1: '14,04KG',
		s: '',
		b2: '110X110X8,00MM',
		a2: '153,38KG',
	},
	{
		b1: '50X50X2,00MM',
		a1: '18,72KG',
		s: '',
		b2: '120X120X3,00MM',
		a2: '67,39KG',
	},
	{
		b1: '50X50X2,50MM',
		a1: '23,40KG',
		s: '',
		b2: '120X120X4,00MM',
		a2: '89,95KG',
	},
	{
		b1: '50X50X3,00MM',
		a1: '28,08KG',
		s: '',
		b2: '120X120X5,00MM',
		a2: '112,32KG',
	},
	{
		b1: '50X50X4,00MM',
		a1: '37,44KG',
		s: '',
		b2: '120X120X6,00MM',
		a2: '128,57KG',
	},
	{
		b1: '50X50X5,00MM',
		a1: '46,80KG',
		s: '',
		b2: '120X120X8,00MM',
		a2: '168,42KG',
	},
	{
		b1: '60X60X1,50MM',
		a1: '16,84KG',
		s: '',
		b2: '140X140X4,00MM',
		a2: '102,25KG',
	},
	{
		b1: '60X60X2,00MM',
		a1: '22,46KG',
		s: '',
		b2: '140X140X5,00MM',
		a2: '126,88KG',
	},
	{
		b1: '60X60X2,50MM',
		a1: '28,08KG',
		s: '',
		b2: '140X140X6,00MM',
		a2: '151,12KG',
	},
	{
		b1: '60X60X3,00MM',
		a1: '33,69KG',
		s: '',
		b2: '140X140X8,00MM',
		a2: '198,49KG',
	},
	{
		b1: '60X60X4,00MM',
		a1: '44,92KG',
		s: '',
		b2: '150X150X3,00MM',
		a2: '84,24KG',
	},
	{
		b1: '60X60X5,00MM',
		a1: '56,16KG',
		s: '',
		b2: '150X150X4,00MM',
		a2: '112,32KG',
	},
	{
		b1: '70X70X1,50MM',
		a1: '19,65KG',
		s: '',
		b2: '150X150X5,00MM',
		a2: '140,40KG',
	},
	{
		b1: '70X70X2,00MM',
		a1: '26,20KG',
		s: '',
		b2: '150X150X6,00MM',
		a2: '162,40KG',
	},
	{
		b1: '70X70X2,50MM',
		a1: '32,76KG',
		s: '',
		b2: '150X150X8,00MM',
		a2: '213,53KG',
	},
	{
		b1: '70X70X3,00MM',
		a1: '39,31KG',
		s: '',
		b2: '200X200X4,00MM',
		a2: '147,36KG',
	},
	{
		b1: '70X70X4,00MM',
		a1: '52,41KG',
		s: '',
		b2: '200X200X5,00MM',
		a2: '183,26KG',
	},
	{
		b1: '70X70X5,00MM',
		a1: '65,52KG',
		s: '',
		b2: '200X200X6,00MM',
		a2: '218,79KG',
	},
	{
		b1: '80X80X2,00MM',
		a1: '29,95KG',
		s: '',
		b2: '200X200X8,00MM',
		a2: '288,71KG',
	},
	{
		b1: '80X80X2,50MM',
		a1: '37,44KG',
		s: '',
		b2: '200X200X10,00MM',
		a2: '357,13KG',
	},
	{
		b1: '80X80X3,00MM',
		a1: '44,92KG',
		s: '',
		b2: '250X250X5,00MM',
		a2: '234,00KG',
	},
	{
		b1: '80X80X4,00MM',
		a1: '59,90KG',
		s: '',
		b2: '250X250X6,00MM',
		a2: '275,18KG',
	},
	{
		b1: '80X80X5,00MM',
		a1: '74,88KG',
		s: '',
		b2: '250X250X8,00MM',
		a2: '363,90KG',
	},
	{
		b1: '80X80X6,00MM',
		a1: '83,46KG',
		s: '',
		b2: '250X250X10,00MM',
		a2: '451,11KG',
	},
];

const ProfilKare: React.FC = () => {
	const navigate = useNavigate();
	const [isSmallScreen, setIsSmallScreen] = React.useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 1100);
		};

		handleResize();
		window.addEventListener('resize', handleResize);
	});
	return (
		<div id="tablo-dikdortgen" className="mx-4 p-4 my-4">
			{isSmallScreen ? (
				<h1
					id="responsive-message"
					className="text-xl font-bold text-center p-4 mx-auto mt-12"
				>
					Tabloyu en verimli şekilde görmek için lütfen sitemizi masaüstünde tam
					ekran olarak açınız.
				</h1>
			) : (
				<>
					<div className="flex items-center justify-evenly my-4 flex-wrap">
						<Button
							type="dashed"
							size="large"
							onClick={() => navigate('/profil-dikdortgen')}
							className="my-1 w-48"
						>
							Dikdörtgen Tablosu
						</Button>
						<Button
							type="primary"
							size="large"
							onClick={() => navigate('/profil-kare')}
							className="my-1 w-48"
						>
							Kare Tablosu
						</Button>
					</div>
					<Table
						columns={columns}
						dataSource={data}
						bordered
						pagination={false}
						title={() => {
							return (
								<h1 className="text-xl font-bold text-center">
									Kare Profil Ağırlık Tablosu
								</h1>
							);
						}}
					/>
				</>
			)}
		</div>
	);
};

export default ProfilKare;

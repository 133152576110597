import React from 'react';
import { useNavigate } from 'react-router-dom';
import CatiImage from '../images/cati_detay.jpg';
import BoyaliSacImage from '../images/boyali_sac.jpg';
import SandvicPanelImage from '../images/sandvich-panel.png';
import { Card } from 'antd';

const CatiGrubu: React.FC = () => {
	const { Meta } = Card;
	const navigate = useNavigate();
	const paragraphStyle = 'my-4 text-xl indent-8';
	const cardStyle = 'w-full md:w-2/5 lg:w-1/5 mb-12';
	const titleStyle = 'text-2xl font-bold text-center mb-8';

	const boyaliDesc = (
		<div>
			<h1 className="text-xl mb-4">Genel Bilgiler</h1>
			<p className="indent-8 mb-4">
				Boyalı trapez saclar, genellikle çatı ve cephe kaplamalarında kullanılan
				dayanıklı ve estetik bir malzemedir. Çelik sacların özel boyalarla
				kaplanması sonucu elde edilir ve farklı renk seçenekleri sunar. Bu
				saclar, yapıların dış etkenlere karşı korunmasını sağlar ve aynı zamanda
				görsel olarak da hoş bir görünüm sunar.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul>
				<li>
					<b>Dayanıklılık:</b>Boyalı trapez saclar, UV ışınları, korozyon ve
					aşındırıcı maddelere karşı yüksek direnç gösterir.
				</li>
				<li>
					<b>Estetik Görünüm:</b>Farklı renk ve desen seçenekleriyle estetik bir
					görünüm sunar.
				</li>
				<li>
					<b>Kolay Uygulama:</b>Hafif yapısı sayesinde kolay monte edilir ve
					işçilik maliyetlerini düşürür.
				</li>
				<li>
					<b>Uzun Ömürlü:</b>Kaliteli boyalarla kaplandığı için uzun yıllar
					boyunca rengini ve dayanıklılığını korur.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Endüstriyel binalar</li>
				<li>Depolar</li>
				<li>Tarım yapıları</li>
				<li>Konut projeleri</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list=disc">
				<li>
					<b>Kalınlık:</b> 0.30 mm - 1.20 mm
				</li>
				<li>
					<b>Genişlik:</b> 800 mm - 1250 mm
				</li>
				<li>
					<b>Boy:</b> İstenilen boyda kesilebilir
				</li>
			</ul>
		</div>
	);
	const sandvicPanel = (
		<div>
			<h1 className="text-xl mb-4">Genel Bilgiler</h1>
			<p className="indent-8 mb-4">
				Sandviç paneller, iki metal yüzey arasında yalıtım malzemesi bulunan
				kompozit yapı elemanlarıdır. Isı, ses ve su yalıtımı sağlayarak enerji
				tasarrufu sağlar ve yapıların konforunu artırır.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Isı Yalıtımı:</b>İçerisindeki yalıtım malzemesi sayesinde mükemmel
					ısı yalıtımı sağlar.
				</li>
				<li>
					<b>Ses Yalıtımı:</b>Gürültü kirliliğini azaltarak sessiz ve konforlu
					ortamlar yaratır.
				</li>
				<li>
					<b>Su Yalıtımı:</b>Su geçirmez yapısı sayesinde binaları nem ve su
					hasarlarından korur.
				</li>
				<li>
					<b>Hızlı Montaj:</b>Modüler yapısı sayesinde hızlı ve kolay montaj
					imkanı sunar.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Endüstriyel binalar</li>
				<li>Soğuk hava depoları</li>
				<li>Spor salonları</li>
				<li>Ticari binalar</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul>
				<li>
					<b>Kalınlık:</b> 50 mm - 200 mm
				</li>
				<li>
					<b>Genişlik:</b> 1000 mm
				</li>
				<li>
					<b>Boy:</b> İstenilen boyda kesilebilir
				</li>
				<li>
					<b>Yalıtım Malzemesi:</b> Poliüretan, Taşyünü veya Polistren
				</li>
			</ul>
		</div>
	);

	return (
		<>
			<div className="m-8">
				<h1 className="text-3xl font-bold text-center mb-4">Çatı Grubu</h1>
				<section className="flex flex-row flex-nowrap my-4">
					<div
						id="cati-paragraphs"
						className="mx-auto w-auto p-4 flex flex-col-reverse md:flex-row lg:flex-row justify-center items-center"
					>
						<div className="mr-0 mt-4 lg:mt-0 lg:mr-8">
							<p className={paragraphStyle}>
								İzmir'deki demir çelik sektöründeki lider firmamız, çatı kaplama
								çözümleri konusunda geniş bir ürün yelpazesi sunmaktadır. Çatı
								grubu ürünlerimiz arasında yer alan boyalı trapez saclar ve
								sandviç paneller, yapıların dayanıklılığını artırmakla kalmaz,
								aynı zamanda estetik bir görünüm kazandırır.
							</p>
							<p className={paragraphStyle}>
								Modern inşaat projelerinin vazgeçilmez unsurları olan bu
								ürünler, yüksek kalite standartlarında üretilir ve çeşitli iklim
								koşullarına karşı üstün koruma sağlar. Boyalı Trapez Saclar ve
								Sandviç Paneller, enerji verimliliği, estetik, kolay montaj ve
								uzun ömürlü kullanım gibi avantajlarıyla öne çıkar.
							</p>
							<p className={paragraphStyle}>
								Firmamız, müşterilerimizin ihtiyaçlarına en uygun çözümleri
								sunmak için sürekli olarak ürün yelpazesini genişletmekte ve en
								son teknoloji ile donatılmış üretim süreçleri kullanmaktadır.
								Çatı grubu ürünlerimiz, inşaat sektöründe, endüstriyel
								tesislerde, ticari yapılarda ve konut projelerinde geniş bir
								kullanım alanına sahiptir. Her projeye uygun özelliklerde ve
								boyutlarda üretilebilen bu malzemeler, yapıların uzun ömürlü ve
								güvenli olmasını sağlar.
							</p>
							<p className={paragraphStyle}>
								Firmamızın sunduğu çatı grubu ürünleri hakkında detaylı bilgi
								almak, projelerinize en uygun çözümleri bulmak ve teknik destek
								almak için bizimle{' '}
								<a href="/contact" onClick={() => navigate('/contact')}>
									iletişime geçebilir
								</a>{' '}
								veya ürün sayfalarımızı inceleyebilirsiniz.
							</p>
						</div>
						<img
							src={CatiImage}
							className="w-full md:w-1/2 lg:w-2/3 h-full md:h-1/2 lg:h-2/3 ml-auto my-auto rounded-md shadow-xl"
							alt="Cati Grubu"
						/>
					</div>
				</section>
				<h2 className={titleStyle}>Çatı Grubu Ürünleri</h2>
				<div
					id="cati-cards"
					className="flex flex-row flex-wrap justify-around gap-4 my-4"
				>
					<Card
						className={cardStyle}
						cover={<img alt="example" src={BoyaliSacImage} />}
					>
						<Meta
							title={
								<h1 className="text-2xl font-bold text-center">
									Boyalı Trapez Saclar
								</h1>
							}
							description={boyaliDesc}
						/>
					</Card>
					<Card
						className={cardStyle}
						cover={<img alt="example" src={SandvicPanelImage} />}
					>
						<Meta
							title={
								<h1 className="text-2xl font-bold text-center">
									Sandviç Paneller
								</h1>
							}
							description={sandvicPanel}
						/>
					</Card>
				</div>
			</div>
		</>
	);
};

export default CatiGrubu;

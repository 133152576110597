import React from 'react';
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const columns = [
	{
		title: 'Boyut (mm)',
		dataIndex: 'boyut',
		key: 'boyut',
	},
	{
		title: 'Ağırlık (kg/m)',
		dataIndex: 'agirlik',
		key: 'agirlik',
	},
];

const dataKare = [
	{
		key: '1',
		boyut: '10x10',
		agirlik: '0.785',
	},
	{
		key: '2',
		boyut: '12x12',
		agirlik: '1.1304',
	},
	{
		key: '3',
		boyut: '14x14',
		agirlik: '1.5386',
	},
	{
		key: '4',
		boyut: '16x16',
		agirlik: '2.0096',
	},
	{
		key: '5',
		boyut: '18x18',
		agirlik: '2.5434',
	},
	{
		key: '6',
		boyut: '20x20',
		agirlik: '3.14',
	},
	{
		key: '7',
		boyut: '25x25',
		agirlik: '4.9063',
	},
	{
		key: '8',
		boyut: '30x30',
		agirlik: '7.065',
	},
];

const HaddeKare: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div id="tablo-kare" className="mx-4 p-4 my-4">
			<h1 className="text-xl font-bold text-center">
				Hadde grubu agirlik tablolari
			</h1>

			<div className="flex items-center justify-evenly my-4 flex-wrap">
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-silme')}
					className="my-1 w-40"
				>
					Silme Tablosu
				</Button>
				<Button
					type="primary"
					size="large"
					onClick={() => navigate('/hadde-kare')}
					className="my-1 w-40"
				>
					Kare Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-lama')}
					className="my-1 w-40"
				>
					Lama Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-kosebent')}
					className="my-1 w-40"
				>
					Kosebent Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npu')}
					className="my-1 w-40"
				>
					NPU Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npi')}
					className="my-1 w-40"
				>
					NPI Tablosu
				</Button>
			</div>
			<Table
				columns={columns}
				dataSource={dataKare}
				bordered
				pagination={false}
				title={() => {
					return (
						<h1 className="text-xl font-bold text-center">Kare Tablosu</h1>
					);
				}}
			/>
		</div>
	);
};

export default HaddeKare;

import React from 'react';
import HakkimizdaBanner from '../images/hakkimizda-banner.jpg';

const Hakkimizda: React.FC = () => {
	const paragraphStyle = 'my-4 text-lg sm:text-xl indent-8 sm:indent-12';

	return (
		<div className="relative bg-gray-100">
			<div className="relative overflow-hidden h-48">
				<img
					src={HakkimizdaBanner}
					alt="Hakkımızda Banner"
					className="w-full h-full object-cover z-0"
				/>
				<div
					className="absolute inset-0 w-full h-full"
					style={{
						background:
							'linear-gradient(to bottom, rgba(250,250,250,0) -10%, rgba(242,239,229,1) 100%)',
					}}
				></div>
				<h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl sm:text-6xl font-bold text-center text-white z-10 w-full sm:w-3/4 sm:mx-auto">
					Hakkımızda
				</h1>
			</div>

			<div className="relative z-10 mt-4 mx-8">
				<section className="flex flex-col items-center">
					<p className={paragraphStyle}>
						1994 yılında İzmir 1. Sanayi Sitesi'nde kurulan firmamız, 30 yıllık
						sektör tecrübesi ile demir-çelik sektöründe güçlü bir yer
						edinmiştir. Müşteri odaklı çözümlerimiz, geniş ürün yelpazemiz ve
						güçlü stoklarımız ile sektörde fark yaratıyoruz.
					</p>
					<p className={paragraphStyle}>
						Kuruluşumuzdan bu yana, en uygun ve en hızlı tedarik ağı ile
						müşterilerimize hizmet vermeyi amaçladık. Yıllar içerisinde elde
						ettiğimiz deneyim ve bilgi birikimi ile demir-çelik ürünlerinde en
						yüksek kaliteyi sunmak için çalışıyoruz.
					</p>
					<p className={paragraphStyle}>
						Demir-çelik sektöründe güvenilir ve sağlam bir partner olarak,
						müşterilerimizin ihtiyaçlarını en iyi şekilde karşılamak için
						sürekli olarak kendimizi geliştiriyoruz. Yenilikçi çözümler ve
						modern teknolojilerle, her zaman daha iyisini sunmayı hedefliyoruz.
					</p>
					<p className={paragraphStyle}>
						Firmamız, çevreye duyarlı ve sürdürülebilir üretim anlayışı ile
						hareket etmekte olup, kalite standartlarına uygun ürünler
						sunmaktadır. Müşteri memnuniyetini her zaman ön planda tutarak, uzun
						vadeli iş birlikleri kurmayı amaçlıyoruz.
					</p>
					<p className={paragraphStyle}>
						İzmir'deki merkezi konumumuz sayesinde, tüm Türkiye'ye ve
						uluslararası pazarlara hızlı ve etkin bir şekilde hizmet
						verebilmekteyiz. Güçlü lojistik ağımız ve deneyimli ekibimiz ile
						müşterilerimize her zaman en iyi hizmeti sunmayı taahhüt ediyoruz.
					</p>
					<p className={paragraphStyle}>
						Bizimle çalışmayı tercih eden müşterilerimiz, kaliteli ürünler,
						rekabetçi fiyatlar ve mükemmel müşteri hizmetleri ile
						karşılaşmaktadır. Siz de bizimle iş birliği yaparak, projelerinizde
						güvenilir bir ortak edinebilirsiniz.
					</p>
				</section>
			</div>
		</div>
	);
};

export default Hakkimizda;

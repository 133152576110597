import React, { useState } from 'react';
import logo from '../images/logo2.png';
import { Menu, MenuProps } from 'antd';
import downKey from '../images/arrow-down.png';
import { useNavigate } from 'react-router-dom';

const Navbar: React.FC = () => {
	const navigate = useNavigate();
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [openKeys, setOpenKeys] = useState<string[]>([]);
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

	type menuItem = Required<MenuProps>['items'][number];

	const onClick: MenuProps['onClick'] = (e) => {
		setSelectedKeys([e.key]);
		setOpenKeys([]);

		switch (e.key) {
			case 'anasayfa':
				navigate('/');
				break;
			case 'about':
				navigate('/about');
				break;
			case 'contact':
				navigate('/contact');
				break;
			case 'boru':
				navigate('/boru');
				break;
			case 'hadde':
				navigate('/hadde');
				break;
			case 'profil':
				navigate('/profil');
				break;
			case 'sac':
				navigate('/sac');
				break;
			case 'cati':
				navigate('/cati');
				break;
			case 'boru_tablo':
				navigate('/boru-tablo');
				break;
			case 'dikdortgen_tablo':
				navigate('/profil-dikdortgen');
				break;
			case 'kare_tablo':
				navigate('/profil-kare');
				break;
			case 'hadde_silme_tablo':
				navigate('/hadde-silme');
				break;
			case 'hadde_kare_tablo':
				navigate('/hadde-kare');
				break;
			case 'hadde_lama_tablo':
				navigate('/hadde-lama');
				break;
			case 'hadde_kosebent_tablo':
				navigate('/hadde-kosebent');
				break;
			case 'hadde_npu_tablo':
				navigate('/hadde-npu');
				break;
			case 'hadde_npi_tablo':
				navigate('/hadde-npi');
				break;
			default:
				break;
		}
	};

	const items: menuItem[] = [
		{
			label: 'Anasayfa',
			key: 'anasayfa',
		},
		{
			label: (
				<div className="flex items-center gap-2 cursor-pointer">
					<span>Ürünler & Ağırlık Tabloları</span>
					{!isMobileMenuOpen && (
						<img src={downKey} className="w-auto h-3" alt="downKey" />
					)}
				</div>
			),
			key: 'tablolar',
			children: [
				{
					label: 'Boru Grubu',
					key: 'boru',
					onTitleClick: (e: any) => onClick(e),
					children: [
						{
							label: 'Ağırlık Tablosu',
							key: 'boru_tablo',
						},
					],
				},
				{
					label: 'Hadde Grubu',
					key: 'hadde',
					onTitleClick: (e: any) => onClick(e),
					children: [
						{
							label: 'Silme Tablosu',
							key: 'hadde_silme_tablo',
						},
						{
							label: 'Kare Tablosu',
							key: 'hadde_kare_tablo',
						},
						{
							label: 'Lama Tablosu',
							key: 'hadde_lama_tablo',
						},
						{
							label: 'Köşebent Tablosu',
							key: 'hadde_kosebent_tablo',
						},
						{
							label: 'NPU Tablosu',
							key: 'hadde_npu_tablo',
						},
						{
							label: 'NPI Tablosu',
							key: 'hadde_npi_tablo',
						},
					],
				},
				{
					label: 'Profil Grubu',
					key: 'profil',
					onTitleClick: (e: any) => onClick(e),
					children: [
						{
							label: 'Dikdörtgen Profil Ağırlık Tablosu',
							key: 'dikdortgen_tablo',
						},
						{
							label: 'Kare Profil Ağırlık Tablosu',
							key: 'kare_tablo',
						},
					],
				},
				{
					label: 'Sac Grubu',
					key: 'sac',
				},
				{
					label: 'Çatı Grubu',
					key: 'cati',
				},
			],
		},
		{
			label: 'Hakkımızda',
			key: 'about',
		},
		{
			label: 'İletişim',
			key: 'contact',
		},
	];

	return (
		<>
			<div
				id="navbar"
				className="flex flex-row justify-between bg-semih items-center h-20 px-4 md:px-8 sticky top-0 z-50 shadow-lg"
			>
				<div id="logo" className="flex-shrink-0">
					<img src={logo} alt="logo" className="max-w-36 h-auto" />
				</div>
				<Menu
					onClick={onClick}
					mode="horizontal"
					items={items}
					onOpenChange={(keys) => setOpenKeys(keys)}
					openKeys={openKeys}
					selectedKeys={selectedKeys}
					className="hidden md:flex justify-center flex-grow bg-semih border-none text-lg"
				/>
				<div
					id="phone"
					className="hidden md:flex items-center justify-center text-nowrap text-xl"
				>
					<span className="mr-3 [&>svg]:h-5 [&>svg]:w-5">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
						>
							<path
								fillRule="evenodd"
								d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
								clipRule="evenodd"
							/>
						</svg>
					</span>
					<a href="tel:+905555762808">0555 762 78 08</a>
				</div>
				<div className="md:hidden">
					{/* Hamburger menu button */}
					<button
						type="button"
						className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
						onClick={() => {
							console.log('Toggling mobile menu');
							setIsMobileMenuOpen(!isMobileMenuOpen);
						}}
					>
						<svg
							className="h-6 w-6"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 6h16M4 12h16M4 18h16"
							/>
						</svg>
					</button>
				</div>
			</div>
			{/* Mobile Menu */}
			{isMobileMenuOpen && (
				<div className="md:hidden bg-semih shadow-lg">
					<Menu
						onClick={onClick}
						mode="inline"
						items={items}
						inlineCollapsed={false}
						className="flex flex-col bg-semih border-none text-lg"
					/>
				</div>
			)}
		</>
	);
};

export default Navbar;

import React from 'react';
import shopimage from '../images/ofis.png';
import uretim from '../images/uretim.png';
import satis from '../images/satis.jpg';
import hizmet from '../images/hizmet.png';
import danisma from '../images/danisma.png';

const AboutUs: React.FC = () => {
	const paragraphStyle = 'my-2';

	return (
		<div className="bg-gray p-6 dark:border-white/10 mx-auto max-w-7xl lg:py-16">
			<div className="flex flex-col-reverse sm:flex-col sm:gap-4 p-4 mx-4 sm:p-6 sm:mx-8 lg:flex-row lg:gap-8 justify-between dark:border-white/10">
				<div className="relative z-10 mt-4">
					<section className="flex flex-col items-center mb-8">
						<h2 className="text-2xl sm:text-3xl font-bold text-center mb-4">
							Hakkımızda
						</h2>
						<p className={paragraphStyle}>
							Kuruluşumuzdan bu yana, en uygun ve en hızlı tedarik ağı ile
							müşterilerimize hizmet vermeyi amaçladık. Yıllar içerisinde elde
							ettiğimiz deneyim ve bilgi birikimi ile demir-çelik ürünlerinde en
							yüksek kaliteyi sunmak için çalışıyoruz.
						</p>
						<p className={paragraphStyle}>
							Firmamız, çevreye duyarlı ve sürdürülebilir üretim anlayışı ile
							hareket etmekte olup, kalite standartlarına uygun ürünler
							sunmaktadır. Müşteri memnuniyetini her zaman ön planda tutarak,
							uzun vadeli iş birlikleri kurmayı amaçlıyoruz.
						</p>
					</section>
				</div>
				<div className="pb-8">
					<img
						src={shopimage}
						className="w-full md:w-1/2 lg:w-full rounded-lg shadow-lg mx-auto "
						alt="Shop"
					/>
				</div>
			</div>

			<div className="flex lg:flex-row flex-col justify-between gap-8 mx-4 sm:mx-8">
				<div className="w-full lg:w-8/12 lg:pt-8">
					<div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md w-full">
						<div className="p-4 pb-6 flex justify-center flex-col items-center">
							<img
								className="md:block hidden rounded-lg"
								src={uretim}
								alt="Alexa featured"
							/>
							<img
								className="md:hidden block"
								src={uretim}
								alt="Alexa featured"
							/>
							<p className="font-medium text-xl leading-5 text-gray-800 mt-4">
								Üretim
							</p>
						</div>
						<div className="p-4 pb-6 flex justify-center flex-col items-center">
							<img
								className="md:block hidden"
								src={satis}
								alt="Olivia featured"
							/>
							<img
								className="md:hidden block"
								src={satis}
								alt="Olivia featured"
							/>
							<p className="font-medium text-xl leading-5 text-gray-800 mt-4">
								Satış
							</p>
						</div>
						<div className="p-4 pb-6 flex justify-center flex-col items-center">
							<img
								className="md:block hidden"
								src={hizmet}
								alt="Liam featued"
							/>
							<img
								className="md:hidden block"
								src={hizmet}
								alt="Liam featued"
							/>
							<p className="font-medium text-xl leading-5 text-gray-800 mt-4">
								Satış Sonrası
							</p>
						</div>
						<div className="p-4 pb-6 flex justify-center flex-col items-center">
							<img
								className="md:block hidden"
								src={danisma}
								alt="Elijah featured"
							/>
							<img
								className="md:hidden block"
								src={danisma}
								alt="Elijah featured"
							/>
							<p className="font-medium text-xl leading-5 text-gray-800 mt-4">
								Danışma
							</p>
						</div>
					</div>
				</div>
				<div className="w-full lg:w-5/12 flex flex-col justify-center">
					<h1 className="text-3xl lg:text-3xl font-bold leading-9 text-gray-800 pb-4 text-center">
						Hikayemiz
					</h1>
					<div className="relative z-10 mt-4 lg:mx-8">
						<section className="flex flex-col items-center">
							<p className={paragraphStyle}>
								1994 yılında İzmir 1. Sanayi Sitesi'nde kurulan firmamız, 30
								yıllık sektör tecrübesi ile demir-çelik sektöründe güçlü bir yer
								edinmiştir. Müşteri odaklı çözümlerimiz, geniş ürün yelpazemiz
								ve güçlü stoklarımız ile sektörde fark yaratıyoruz.
							</p>
							<p className={paragraphStyle}>
								İzmir'deki merkezi konumumuz sayesinde, tüm Türkiye'ye ve
								uluslararası pazarlara hızlı ve etkin bir şekilde hizmet
								verebilmekteyiz. Güçlü lojistik ağımız ve deneyimli ekibimiz ile
								müşterilerimize her zaman en iyi hizmeti sunmayı taahhüt
								ediyoruz.
							</p>
						</section>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;

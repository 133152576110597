import React from 'react';
import { Button, Card } from 'antd';
import HaddeDetay from '../images/hadde_detay.jpg';
import NPUImage from '../images/npu-card.jpg';
import NPIImage from '../images/npi-card.jpg';
import KosebentImage from '../images/kosebent-card.jpg';
import LamaSilmeImage from '../images/lama-silme-card.jpg';
import HEAHEBImage from '../images/hea-heb-card.jpg';
import DoluKareImage from '../images/dolu-kare-card.jpg';
import TeDemirImage from '../images/te-demir.jpg';
import TransmisyonImage from '../images/transmisyon-card.jpg';
import '../index.css';
import { useNavigate } from 'react-router-dom';

const HaddeGrubu: React.FC = () => {
	const { Meta } = Card;
	const navigate = useNavigate();
	const paragraphStyle = 'my-4 text-xl indent-8';
	const subTitleStyle = 'text-2xl font-bold text-center mb-4';
	const cardStyle = 'w-full md:w-2/5 lg:w-1/5 mb-12';
	const titleStyle = 'text-2xl font-bold text-center mb-8';
	const buttonStyle = 'mt-3 flex flex-col items-center justify-center w-full';

	const NPUProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				NPU profiller, Içe aktarma, yüksek mukavemet, dayanıklılığı ve esneklik
				gibi özelliklerle bilinirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Yüksek basınç ve sıcaklık koşullarına
					dayanıklıdır.
				</li>
				<li>
					<b>Dayanıklılık:</b> Aşınmaya ve korozyona karşı dirençlidir.
				</li>
				<li>
					<b>Esneklik:</b> Çeşitli boyut ve kalınlıklarda üretilebilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İnşaat sektörü</li>
				<li>Makine imalatı</li>
				<li>Raf sistemleri</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 40 mm x 20 mm'den 400 mm x 100 mm'ye kadar
				</li>
				<li>
					<b>Kalınlık:</b> 4 mm - 12 mm
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>

			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/hadde-npu')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);
	const NPIProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				NPI profiller, I şeklinde olan ve genellikle ağır yapı ve köprü
				inşaatlarında kullanılan çelik profillerdir. Yüksek mukavemeti ile
				bilinirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Ağır yük taşıma kapasitesine sahiptir.
				</li>
				<li>
					<b>Dayanıklılık:</b> Uzun ömürlü ve dayanıklıdır.
				</li>
				<li>
					<b>Esneklik:</b> Farklı boyut ve kalınlıklarda üretilebilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Köprü inşaatları</li>
				<li>Büyük ölçekli yapılar</li>
				<li>Endüstriyel tesisler</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 80 mm x 42 mm'den 600 mm x 220 mm'ye kadar
				</li>
				<li>
					<b>Kalınlık:</b> 5 mm - 21 mm
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>
			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/hadde-npi')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);
	const KosebentProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Köşebentler, L şeklinde olan ve genellikle yapıların köşe ve
				kenarlarında kullanılan çelik profillerdir. Çeşitli endüstriyel ve
				inşaat uygulamalarında kullanılırlar.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Dayanıklılık:</b> Aşınmaya ve darbelere karşı dirençlidir.
				</li>
				<li>
					<b>Çok Yönlü Kullanım:</b> Farklı uygulama alanlarında kullanılabilir.
				</li>
				<li>
					<b>Kolay Montaj:</b> Kolay montaj yapabilmektedir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İnşaat Sektörü</li>
				<li>Makine İmalatı</li>
				<li>Mobilya Üretimi</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 20 mm x 20 mm'den 200 mm x 200 mm'ye kadar
				</li>
				<li>
					<b>Kalınlık:</b> 3 mm - 20 mm
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>
			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/hadde-kosebent')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);
	const LamaSilmeProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Lama ve silme, düz ve dikdörtgen kesitli çelik çubuklardır. Genellikle
				inşaat ve imalat sektöründe kullanılırlar.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Dayanıklı ve uzun ömürlüdür
				</li>
				<li>
					<b>Çok Yönlü Kullanım:</b> Farklı uygulama alanlarında kullanılabilir.
				</li>
				<li>
					<b>Kolay Montaj:</b> Kolay montaj yapabilmektedir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İnşaat Sektörü</li>
				<li>Makine İmalatı</li>
				<li>Mobilya Üretimi</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 20 mm x 5 mm'den 200 mm x 25 mm'ye kadar
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>
			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/hadde-lama')}
			>
				Lama Ağırlık Tablosu
			</Button>
			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/hadde-silme')}
			>
				Silme Ağırlık Tablosu
			</Button>
		</div>
	);
	const HEAHEBProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				HEA ve HEB profiller, H şeklinde olan ve genellikle büyük ölçekli
				yapılar ile köprü inşaatlarında kullanılan çelik profillerdir. Yüksek
				mukavemeti ile bilinirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Ağır yük taşıma kapasitesine sahiptir.
				</li>
				<li>
					<b>Dayanıklılık:</b> Uzun ömürlü ve dayanıklıdır.
				</li>
				<li>
					<b>Esneklik:</b> Farklı boyut ve kalınlıklarda üretilebilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Büyük ölçekli yapılar</li>
				<li>Köprü inşaatları</li>
				<li>Endüstriyel tesisler</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 100 mm x 100 mm'den 1000 mm x 300 mm'ye kadar
				</li>
				<li>
					<b>Kalınlık:</b> 6 mm - 25 mm
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>
		</div>
	);
	const DoluKareProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Dolu kare profiller, kare kesitli ve dolu çelik çubuklardır. Genellikle
				inşaat ve imalat sektöründe kullanılırlar.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Dayanıklı ve uzun ömürlüdür.
				</li>
				<li>
					<b>Kolay İşlenebilirlik::</b> Kesimi ve montajı kolaydır.
				</li>
				<li>
					<b>Çok Yönlü Kullanım:</b> Farklı uygulama alanlarında kullanılabilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İnşaat sektörü</li>
				<li>Makine imalatı</li>
				<li>Mobilya üretimi</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 10 mm x 10 mm'den 150 mm x 150 mm'ye kadar
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>
			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/hadde-kare')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);
	const TeDemirProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				TE demir, T şeklinde olan ve genellikle yapıların taşıyıcı elemanlarında
				kullanılan çelik profillerdir. Yüksek mukavemeti ile bilinirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Ağır yük taşıma kapasitesine sahiptir.
				</li>
				<li>
					<b>Dayanıklılık:</b> Uzun ömürlü ve dayanıklıdır.
				</li>
				<li>
					<b>Esneklik:</b> Farklı boyut ve kalınlıklarda üretilebilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Taşıyıcı yapılar</li>
				<li>Makine imalatı</li>
				<li>İnşaat sektörü</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 30 mm x 30 mm'den 200 mm x 200 mm'ye kadar
				</li>
				<li>
					<b>Kalınlık:</b> 4 mm - 12 mm
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>
		</div>
	);
	const TransmisyonProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Transmisyon çelikleri, yüksek mukavemet ve dayanıklılığa sahip çelik
				çubuklardır. Genellikle makine imalatı ve otomotiv sektöründe
				kullanılırlar.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> ADayanıklı ve uzun ömürlüdür.
				</li>
				<li>
					<b>Kolay İşlenebilirlik:</b> Kesimi ve montajı kolaydır.
				</li>
				<li>
					<b>Çok Yönlü Kullanım:</b> Farklı uygulama alanlarında kullanılabilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Otomotiv sektörü</li>
				<li>Makine imalatı</li>
				<li>Endüstriyel uygulamalar</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 10 mm - 200 mm çap aralığında
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>
		</div>
	);

	return (
		<div className="m-8">
			<h1 className="text-3xl font-bold text-center mb-4">Hadde Grubu</h1>
			<section className="flex flex-row flex-nowrap my-4">
				<div
					id="hadde-paragraphs"
					className="mx-auto w-auto p-4 flex flex-col-reverse md:flex-row lg:flex-row justify-center items-center"
				>
					<div>
						<p className={paragraphStyle}>
							Hadde grubu ürünlerimiz, İzmir'deki demir çelik sektöründe geniş
							bir kullanım alanına sahip olan yüksek kaliteli malzemelerden
							oluşur. Firmamız, NPU profil, NPI profil, köşebent, lama-silme,
							HEA-HEB profil, dolu kare, TE demir ve transmisyon gibi çeşitli
							hadde ürünlerini müşterilerimize sunmaktadır. Her biri farklı
							özelliklere sahip olan bu ürünler, inşaat, makine imalatı, altyapı
							ve endüstriyel uygulamalarda kullanılmak üzere tasarlanmıştır.
						</p>
						<p className={paragraphStyle}>
							Hadde grubu ürünlerimiz, yüksek mukavemet, dayanıklılık ve
							işlevsellik sunarak projelerinizin ihtiyaçlarına en uygun
							çözümleri sağlar. Ürünlerimiz hakkında daha fazla bilgi edinmek ve
							projeleriniz için en doğru hadde türünü seçmek için ilgili
							sayfaları inceleyebilir veya bizimle iletişime geçebilirsiniz.
						</p>
					</div>
					<img
						src={HaddeDetay}
						className="w-full md:w-1/2 lg:w-2/3 h-full md:h-1/2 lg:h-2/3 my-auto rounded-md shadow-xl"
						alt="Hadde"
					/>
				</div>
			</section>
			<h2 className={titleStyle}>Hadde Grubu Ürünleri</h2>
			<div
				id="hadde-cards"
				className="flex flex-row flex-wrap justify-around gap-4 my-4"
			>
				<Card className={cardStyle} cover={<img alt="NPU" src={NPUImage} />}>
					<Meta
						title={<h2 className={subTitleStyle}>NPU Profil</h2>}
						description={NPUProfilDesc}
					/>
				</Card>
				<Card className={cardStyle} cover={<img alt="NPI" src={NPIImage} />}>
					<Meta
						title={<h2 className={subTitleStyle}>NPI Profil</h2>}
						description={NPIProfilDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Köşebent" src={KosebentImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Köşebent</h2>}
						description={KosebentProfilDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Lama-Silme" src={LamaSilmeImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Lama-Silme</h2>}
						description={LamaSilmeProfilDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="HEA-HEB" src={HEAHEBImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>HEA-HEB Profil</h2>}
						description={HEAHEBProfilDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Dolu Kare" src={DoluKareImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Dolu Kare</h2>}
						description={DoluKareProfilDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="TE-Demir" src={TeDemirImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>TE Demir</h2>}
						description={TeDemirProfilDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Transmisyon" src={TransmisyonImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Transmisyon</h2>}
						description={TransmisyonProfilDesc}
					/>
				</Card>
			</div>
		</div>
	);
};

export default HaddeGrubu;

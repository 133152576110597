import React from "react";
import ContactBanner from "../images/contact-us-banner.jpg";

const Iletisim: React.FC = () => {
  const contactSectionStyle = "my-8 w-full h-auto px-4 sm:px-8";

  return (
    <div className="relative bg-gray-100">
      <div className="relative overflow-hidden h-48">
        <img
          src={ContactBanner}
          alt="Hakkımızda Banner"
          className="w-full h-full object-cover z-0"
        />
        <div
          className="absolute inset-0 w-full h-full"
          style={{
            background:
              "linear-gradient(to bottom, rgba(250,250,250,0) -10%, rgba(242,239,229,1) 100%)",
          }}
        ></div>
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl sm:text-6xl font-bold text-center text-white z-10 w-full sm:w-3/4 sm:mx-auto">
          İletişim
        </h1>
      </div>

      <div className="relative z-10 mt-8">
        <section className="flex flex-col items-center">
          <div className={contactSectionStyle}>
            <div className="flex flex-col items-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 w-full max-w-lg">
                <ContactInfo
                  icon={<AddressIcon />}
                  label="Adres"
                  value="Mersinli Mah, 2829 Sok, 1.sanayi Sitesi No 9, 35170 Konak"
                />
                <ContactInfo
                  icon={<EmailIcon />}
                  label="Elektronik Posta"
                  value="info@ertunametal.com"
                />
                <ContactInfo
                  icon={<PhoneIcon />}
                  label="Telefon"
                  value="0555 762 78 08"
                />
                <ContactInfo
                  icon={<FaxIcon />}
                  label="Faks"
                  value="0232 459 62 80"
                />
              </div>
            </div>
          </div>

          <div className={contactSectionStyle}>
            <div className="flex flex-col items-center">
              <h2 className="text-xl sm:text-2xl font-bold text-center mb-4">
                Mesaj Gönder
              </h2>
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="Adınız"
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-last-name"
                      type="text"
                      placeholder="Soyadınız"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-email"
                      type="email"
                      placeholder="E-posta Adresiniz"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <textarea
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-32 resize-none"
                      id="grid-message"
                      placeholder="Mesajınızı buraya yazınız..."
                    ></textarea>
                  </div>
                </div>
                <div className="container px-10 mx-0 min-w-full flex flex-col items-center">
                  <button className="bg-lightBlue hover:bg-blue text-white font-bold py-2 px-4 border border-blue-700 rounded">
                    Gönder
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className={contactSectionStyle}>
            <h2 className="text-xl sm:text-2xl font-bold text-center mb-4">
              Konum
            </h2>
            <div className="rounded-lg overflow-hidden w-full border">
              <iframe
                className="w-full"
                title="company-location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d195.3444172155978!2d27.17837502437382!3d38.429671739199584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b962834d0d8ec1%3A0x642dabc03c32d769!2sErtuna%20Metal%20Sanayi%20ve%20Ticaret!5e0!3m2!1str!2snl!4v1719837461877!5m2!1str!2snl"
                allowFullScreen
                aria-hidden="false"
                tabIndex={0}
              ></iframe>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const AddressIcon = () => (
  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
      <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
    </svg>
  </span>
);

const EmailIcon = () => (
  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
      <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
    </svg>
  </span>
);

const PhoneIcon = () => (
  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
        clip-rule="evenodd"
      />
    </svg>
  </span>
);

const FaxIcon = () => (
  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z"
        clip-rule="evenodd"
      />
    </svg>
  </span>
);

interface ContactInfoProps {
  icon: JSX.Element;
  label: string;
  value: string;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ icon, label, value }) => {
  return (
    <div className="flex items-center">
      {icon}
      <div className="ml-2">
        <p className="text-lg font-semibold">{label}</p>
        <p className="text-gray-600">{value}</p>
      </div>
    </div>
  );
};

export default Iletisim;

import React from 'react';
// import { useNavigate } from "react-router-dom";
import BoruImage from '../images/boru_detay.jpg';
import IndustrialPipeImage from '../images/industrial_pipes.jpg';
import GalvanizedPipesImage from '../images/galvanized_pipes.jpg';
import PlumbingPipesImage from '../images/plumbing_pipes.jpg';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';

const BoruGrubu: React.FC = () => {
	const { Meta } = Card;
	const navigate = useNavigate();
	const paragraphStyle = 'my-4 text-xl indent-8';
	const titleStyle = 'text-2xl font-bold text-center mb-4';
	const cardStyle = 'w-full md:w-2/5 lg:w-2/5';
	const buttonStyle = 'mt-3 flex flex-col items-center justify-center w-full';

	const sanayiBorulariDesc = (
		<div>
			<h1 className="text-xl mb-4">Genel Bilgiler</h1>
			<p className="indent-8 mb-4">
				Sanayi boruları, endüstriyel tesislerde, imalat süreçlerinde ve çeşitli
				makine ekipmanlarında kullanılan borulardır. Yüksek mukavemeti ve
				dayanıklılığı ile bilinirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Yüksek basınç ve sıcaklık koşullarına
					dayanıklıdır.
				</li>
				<li>
					<b>Dayanıklılık:</b> Aşınmaya ve korozyona karşı dirençlidir.
				</li>
				<li>
					<b>Çok Yönlü Kullanım:</b> Çeşitli endüstriyel uygulamalarda
					kullanılabilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Endüstriyel tesisler</li>
				<li>Makine imalatı</li>
				<li>Petrol ve gaz sektörü</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Çap:</b> 10 mm - 1200 mm
				</li>
				<li>
					<b>Kalınlık:</b> 1 mm - 50 mm
				</li>
				<li>
					<b>Malzeme:</b> Karbon çelik, paslanmaz çelik
				</li>
			</ul>

			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/boru-tablo')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);
	const galvanizBorularDesc = (
		<div>
			<h1 className="text-xl mb-4">Genel Bilgiler</h1>
			<p className="indent-8 mb-4">
				Galvaniz borular, çinko kaplama ile korunan borulardır. Korozyona karşı
				yüksek direnç gösterirler ve uzun ömürlüdürler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Korozyon Direnci:</b> Çinko kaplama sayesinde uzun ömürlüdür.
				</li>
				<li>
					<b>Dayanıklılık:</b> Dış etkenlere karşı yüksek direnç gösterir.
				</li>
				<li>
					<b>Çevre Dostu:</b> Geri dönüştürülebilir malzemeden üretilmiştir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>Su ve gaz hatları</li>
				<li>Çit ve korkuluk sistemleri</li>
				<li>İnşaat ve altyapı projeleri</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Çap:</b> 1/2" - 6"
				</li>
				<li>
					<b>Kalınlık:</b> 1.5 mm - 6 mm
				</li>
				<li>
					<b>Uzunluk:</b> 6 m veya istenilen uzunlukta
				</li>
			</ul>

			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/boru-tablo')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);
	const suTesisatBorulariDesc = (
		<div>
			<h1 className="text-xl mb-4">Genel Bilgiler</h1>
			<p className="indent-8 mb-4">
				Su tesisat boruları, suyun taşınması ve dağıtılması için kullanılan
				borulardır. Genellikle PVC, PPRC veya metal malzemelerden üretilirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Hijyenik:</b>Su taşıma için uygun malzemelerden üretilmiştir.
				</li>
				<li>
					<b>Dayanıklılık:</b>Basınca ve kimyasallara karşı dirençlidir.
				</li>
				<li>
					<b>Kolay Montaj:</b>Hafif ve kolay monte edilebilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İçme suyu hatları</li>
				<li>Atık su sistemleri</li>
				<li>Yangın söndürme sistemleri</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Çap:</b> 16 mm - 630 mm
				</li>
				<li>
					<b>Malzeme:</b> PVC, PPRC, metal
				</li>
				<li>
					<b>Basınç Dayanımı:</b> PN10 - PN25
				</li>
			</ul>

			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/boru-tablo')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);
	return (
		<>
			<div className="m-8">
				<h1 className="text-3xl font-bold text-center mb-4">Boru Grubu</h1>
				<section className="flex flex-row flex-nowrap my-4">
					<div
						id="boru-paragraphs"
						className="mx-auto w-auto p-4 flex flex-col-reverse md:flex-row lg:flex-row justify-center items-center"
					>
						<div>
							<p className={paragraphStyle}>
								Boru grubu ürünlerimiz, İzmir'deki demir çelik sektöründe geniş
								bir kullanım alanına sahip olan yüksek kaliteli malzemelerden
								oluşur. Firmamız, sanayi boruları, galvaniz borular ve su
								tesisat boruları gibi çeşitli boru türlerini müşterilerimize
								sunmaktadır. Her biri farklı özelliklere sahip olan bu ürünler,
								inşaat, sanayi, altyapı ve tesisat uygulamalarında kullanılmak
								üzere tasarlanmıştır.
							</p>
							<p className={paragraphStyle}>
								Boru grubu ürünlerimiz, yüksek mukavemet, dayanıklılık ve
								işlevsellik sunarak projelerinizin ihtiyaçlarına en uygun
								çözümleri sağlar. Ürünlerimiz hakkında daha fazla bilgi edinmek
								ve projeleriniz için en doğru boru türünü seçmek için ilgili
								sayfaları inceleyebilir veya bizimle iletişime geçebilirsiniz.
							</p>
						</div>
						<img
							src={BoruImage}
							className="w-full md:w-1/2 lg:w-full h-full md:h-1/2 lg:h-2/3 my-auto rounded-md shadow-xl"
							alt="Boru Grubu"
						/>
					</div>
				</section>

				<h2 className={titleStyle}>Boru Grubu Ürünleri</h2>
				<div
					id="boru-cards"
					className="flex flex-col md:flex-col lg:flex-row justify-around gap-4"
				>
					<Card
						className={cardStyle}
						cover={<img alt="example" src={IndustrialPipeImage} />}
					>
						<Meta
							title={
								<h1 className="text-2xl font-bold text-center">
									Sanayi Boruları
								</h1>
							}
							description={sanayiBorulariDesc}
						/>
					</Card>
					<Card
						className={cardStyle}
						cover={<img alt="example" src={GalvanizedPipesImage} />}
					>
						<Meta
							title={
								<h1 className="text-2xl font-bold text-center">
									Galvaniz Borular
								</h1>
							}
							description={galvanizBorularDesc}
						/>
					</Card>
					<Card
						className={cardStyle}
						cover={<img alt="example" src={PlumbingPipesImage} />}
					>
						<Meta
							title={
								<h1 className="text-2xl font-bold text-center">
									Su Tesisat Boruları
								</h1>
							}
							description={suTesisatBorulariDesc}
						/>
					</Card>
				</div>
			</div>
		</>
	);
};

export default BoruGrubu;

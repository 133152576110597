import React from 'react';
import { Carousel, ConfigProvider } from 'antd';
import BoruImage from '../images/new_carousel_images/silindir_metal_yei.jpeg';
import CatiImage from '../images/cati.jpg';
import ProfilImage from '../images/profil.jpg';
import SacImage from '../images/sac.jpg';
import HaddeImage from '../images/hadde.jpg';

const CarouselComponent: React.FC = () => {
	const imageClass = 'w-full h-96 mx-auto object-cover';
	const textClass = 'text-xl font-bold text-center m-3';
	return (
		<ConfigProvider
			theme={{
				components: {
					Carousel: {
						dotHeight: 10,
						dotOffset: 450,
						arrowSize: 50,
					},
				},
			}}
		>
			<div className="relative bg-gray z-0 w-full h-auto pb-4 dark:border-white/10 block">
				<Carousel
					arrows
					nextArrow={
						<div className="absolute top-1/2 -mt-12 right-4 z-10 bg-white p-2 rounded-full shadow-lg"></div>
					}
					prevArrow={
						<div className="absolute top-1/2 -mt-12 left-4 z-10 bg-white p-2 rounded-full shadow-lg"></div>
					}
					effect="fade"
					swipeToSlide
				>
					<div className="carousel-item text-center">
						<img src={BoruImage} alt="Boru Grubu" className={imageClass} />
						<h3 className={textClass}>Boru Grubu</h3>
					</div>
					<div className="carousel-item text-center">
						<img src={CatiImage} alt="Çatı Grubu" className={imageClass} />
						<h3 className={textClass}>Çatı Grubu</h3>
					</div>
					<div className="carousel-item text-center">
						<img src={ProfilImage} alt="Profil Grubu" className={imageClass} />
						<h3 className={textClass}>Profil Grubu</h3>
					</div>
					<div className="carousel-item text-center">
						<img src={SacImage} alt="Sac Grubu" className={imageClass} />
						<h3 className={textClass}>Sac Grubu</h3>
					</div>
					<div className="carousel-item text-center">
						<img src={HaddeImage} alt="Hadde Grubu" className={imageClass} />
						<h3 className={textClass}>Hadde Grubu</h3>
					</div>
				</Carousel>
			</div>
		</ConfigProvider>
	);
};

export default CarouselComponent;

import React, { useEffect } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../index.css';

interface DataType {
	key: string;
	diameter: string;
	'0_6mm': string;
	'0_7mm': string;
	'0_8mm': string;
	'1_0mm': string;
	'1_2mm': string;
	'1_5mm': string;
	'2_0mm': string;
	'2_5mm': string;
	'3_0mm': string;
	'3_5mm': string;
	'4_0mm': string;
	'4_5mm': string;
	'5_0mm': string;
	'5_5mm': string;
	'6_0mm': string;
}

const columns: ColumnsType<DataType> = [
	{
		title: 'SANAYİ BORULARI AĞIRLIKLARI (kg/mt)',
		dataIndex: 'mainTitle',
		key: 'mainTitle',
		responsive: ['md'],
		children: [
			{
				title: 'Dış Çap (mm)',
				align: 'right',
				dataIndex: 'diameter',
				key: 'diameter',
				render: (value, _record, _index) => <b>{value}</b>,
			},
			{
				title: 'Sanayi Boru Et Kalınlığı (mm)',
				children: [
					{
						title: '0.6mm',
						dataIndex: '0_6mm',
						key: '0_6mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '0.7mm',
						dataIndex: '0_7mm',
						key: '0_7mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '0.8mm',
						dataIndex: '0_8mm',
						key: '0_8mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '1.0mm',
						dataIndex: '1_0mm',
						key: '1_0mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '1.2mm',
						dataIndex: '1_2mm',
						key: '1_2mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '1.5mm',
						dataIndex: '1_5mm',
						key: '1_5mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '2.0mm',
						dataIndex: '2_0mm',
						key: '2_0mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '2.5mm',
						dataIndex: '2_5mm',
						key: '2_5mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '3.0mm',
						dataIndex: '3_0mm',
						key: '3_0mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '3.5mm',
						dataIndex: '3_5mm',
						key: '3_5mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '4.0mm',
						dataIndex: '4_0mm',
						key: '4_0mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '4.5mm',
						dataIndex: '4_5mm',
						key: '4_5mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '5.0mm',
						dataIndex: '5_0mm',
						key: '5_0mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '5.5mm',
						dataIndex: '5_5mm',
						key: '5_5mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
					{
						title: '6.0mm',
						dataIndex: '6_0mm',
						key: '6_0mm',
						align: 'right',
						render: (text) => <b>{text}</b> || '',
					},
				],
				fixed: 'left',
			},
		],
	},
];

const data: DataType[] = [
	{
		key: '1',
		diameter: '10',
		'0_6mm': '0.11',
		'0_7mm': '0.15',
		'0_8mm': '0.20',
		'1_0mm': '0.22',
		'1_2mm': '0.26',
		'1_5mm': '0.31',
		'2_0mm': '',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '2',
		diameter: '13',
		'0_6mm': '0.18',
		'0_7mm': '0.21',
		'0_8mm': '0.27',
		'1_0mm': '0.30',
		'1_2mm': '0.35',
		'1_5mm': '0.42',
		'2_0mm': '',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '3',
		diameter: '16',
		'0_6mm': '0.23',
		'0_7mm': '0.26',
		'0_8mm': '0.34',
		'1_0mm': '0.37',
		'1_2mm': '0.44',
		'1_5mm': '0.54',
		'2_0mm': '',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '4',
		diameter: '17',
		'0_6mm': '0.24',
		'0_7mm': '0.28',
		'0_8mm': '0.36',
		'1_0mm': '0.40',
		'1_2mm': '0.47',
		'1_5mm': '0.57',
		'2_0mm': '0.74',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '5',
		diameter: '18',
		'0_6mm': '0.26',
		'0_7mm': '0.30',
		'0_8mm': '0.38',
		'1_0mm': '0.42',
		'1_2mm': '0.50',
		'1_5mm': '0.61',
		'2_0mm': '0.79',
		'2_5mm': '0.96',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '6',
		diameter: '19',
		'0_6mm': '0.27',
		'0_7mm': '0.32',
		'0_8mm': '0.40',
		'1_0mm': '0.44',
		'1_2mm': '0.53',
		'1_5mm': '0.65',
		'2_0mm': '0.84',
		'2_5mm': '1.02',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '7',
		diameter: '20',
		'0_6mm': '0.29',
		'0_7mm': '0.33',
		'0_8mm': '0.42',
		'1_0mm': '0.47',
		'1_2mm': '0.56',
		'1_5mm': '0.68',
		'2_0mm': '0.89',
		'2_5mm': '1.08',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '8',
		diameter: '21',
		'0_6mm': '0.30',
		'0_7mm': '0.35',
		'0_8mm': '0.45',
		'1_0mm': '0.49',
		'1_2mm': '0.59',
		'1_5mm': '0.72',
		'2_0mm': '0.94',
		'2_5mm': '1.14',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '9',
		diameter: '22',
		'0_6mm': '0.32',
		'0_7mm': '0.37',
		'0_8mm': '0.47',
		'1_0mm': '0.52',
		'1_2mm': '0.62',
		'1_5mm': '0.76',
		'2_0mm': '0.99',
		'2_5mm': '1.20',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '10',
		diameter: '25',
		'0_6mm': '0.36',
		'0_7mm': '0.42',
		'0_8mm': '0.53',
		'1_0mm': '0.59',
		'1_2mm': '0.70',
		'1_5mm': '0.87',
		'2_0mm': '1.13',
		'2_5mm': '1.39',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '11',
		diameter: '27',
		'0_6mm': '0.39',
		'0_7mm': '0.45',
		'0_8mm': '0.58',
		'1_0mm': '0.64',
		'1_2mm': '0.76',
		'1_5mm': '0.94',
		'2_0mm': '1.23',
		'2_5mm': '1.51',
		'3_0mm': '1.77',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '12',
		diameter: '28',
		'0_6mm': '0.40',
		'0_7mm': '0.47',
		'0_8mm': '0.60',
		'1_0mm': '0.67',
		'1_2mm': '0.79',
		'1_5mm': '0.98',
		'2_0mm': '1.28',
		'2_5mm': '1.57',
		'3_0mm': '1.84',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '13',
		diameter: '30',
		'0_6mm': '',
		'0_7mm': '0.51',
		'0_8mm': '0.65',
		'1_0mm': '0.72',
		'1_2mm': '0.85',
		'1_5mm': '1.05',
		'2_0mm': '1.38',
		'2_5mm': '1.70',
		'3_0mm': '2.00',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '14',
		diameter: '32',
		'0_6mm': '',
		'0_7mm': '0.54',
		'0_8mm': '0.69',
		'1_0mm': '0.77',
		'1_2mm': '0.91',
		'1_5mm': '1.13',
		'2_0mm': '1.48',
		'2_5mm': '1.82',
		'3_0mm': '2.15',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '15',
		diameter: '35',
		'0_6mm': '',
		'0_7mm': '0.59',
		'0_8mm': '0.76',
		'1_0mm': '0.84',
		'1_2mm': '1.00',
		'1_5mm': '1.24',
		'2_0mm': '1.63',
		'2_5mm': '2.00',
		'3_0mm': '2.37',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '16',
		diameter: '38',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '0.82',
		'1_0mm': '0.91',
		'1_2mm': '1.09',
		'1_5mm': '1.35',
		'2_0mm': '1.78',
		'2_5mm': '2.19',
		'3_0mm': '2.59',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '17',
		diameter: '40',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '0.87',
		'1_0mm': '0.96',
		'1_2mm': '1.15',
		'1_5mm': '1.40',
		'2_0mm': '1.87',
		'2_5mm': '2.31',
		'3_0mm': '2.74',
		'3_5mm': '3.15',
		'4_0mm': '3.55',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '18',
		diameter: '42',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '0.91',
		'1_0mm': '1.01',
		'1_2mm': '1.21',
		'1_5mm': '1.50',
		'2_0mm': '1.97',
		'2_5mm': '2.44',
		'3_0mm': '2.89',
		'3_5mm': '3.32',
		'4_0mm': '3.75',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '19',
		diameter: '45',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '0.98',
		'1_0mm': '1.09',
		'1_2mm': '1.30',
		'1_5mm': '1.61',
		'2_0mm': '2.12',
		'2_5mm': '2.62',
		'3_0mm': '3.11',
		'3_5mm': '3.58',
		'4_0mm': '4.04',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '20',
		diameter: '48',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '1.05',
		'1_0mm': '1.16',
		'1_2mm': '1.38',
		'1_5mm': '1.73',
		'2_0mm': '2.24',
		'2_5mm': '2.81',
		'3_0mm': '3.33',
		'3_5mm': '3.84',
		'4_0mm': '4.34',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '21',
		diameter: '50',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '1.09',
		'1_0mm': '1.20',
		'1_2mm': '1.44',
		'1_5mm': '1.79',
		'2_0mm': '2.37',
		'2_5mm': '2.93',
		'3_0mm': '3.48',
		'3_5mm': '4.01',
		'4_0mm': '4.54',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '22',
		diameter: '51',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '1.11',
		'1_0mm': '1.23',
		'1_2mm': '1.47',
		'1_5mm': '1.83',
		'2_0mm': '2.42',
		'2_5mm': '2.99',
		'3_0mm': '3.55',
		'3_5mm': '4.10',
		'4_0mm': '4.64',
		'4_5mm': '',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '23',
		diameter: '57',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '1.38',
		'1_2mm': '1.65',
		'1_5mm': '2.05',
		'2_0mm': '2.71',
		'2_5mm': '3.36',
		'3_0mm': '5.00',
		'3_5mm': '4.62',
		'4_0mm': '5.23',
		'4_5mm': '5.83',
		'5_0mm': '',
		'5_5mm': '',
		'6_0mm': '',
	},
	{
		key: '24',
		diameter: '60',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '1.45',
		'1_2mm': '1.74',
		'1_5mm': '2.16',
		'2_0mm': '2.86',
		'2_5mm': '3.55',
		'3_0mm': '4.22',
		'3_5mm': '4.88',
		'4_0mm': '5.52',
		'4_5mm': '6.16',
		'5_0mm': '6.78',
		'5_5mm': '7.39',
		'6_0mm': '',
	},
	{
		key: '25',
		diameter: '63',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '1.53',
		'1_2mm': '1.83',
		'1_5mm': '2.28',
		'2_0mm': '3.01',
		'2_5mm': '3.72',
		'3_0mm': '4.44',
		'3_5mm': '5.14',
		'4_0mm': '5.82',
		'4_5mm': '6.49',
		'5_0mm': '7.14',
		'5_5mm': '7.79',
		'6_0mm': '',
	},
	{
		key: '26',
		diameter: '70',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '2.03',
		'1_5mm': '2.53',
		'2_0mm': '3.35',
		'2_5mm': '4.16',
		'3_0mm': '4.96',
		'3_5mm': '5.74',
		'4_0mm': '6.51',
		'4_5mm': '7.27',
		'5_0mm': '8.01',
		'5_5mm': '8.75',
		'6_0mm': '',
	},
	{
		key: '27',
		diameter: '72',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '2.09',
		'1_5mm': '2.60',
		'2_0mm': '3.45',
		'2_5mm': '4.28',
		'3_0mm': '5.10',
		'3_5mm': '5.91',
		'4_0mm': '6.71',
		'4_5mm': '7.51',
		'5_0mm': '8.25',
		'5_5mm': '9.02',
		'6_0mm': '',
	},
	{
		key: '28',
		diameter: '76',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '2.21',
		'1_5mm': '2.75',
		'2_0mm': '3.65',
		'2_5mm': '4.53',
		'3_0mm': '5.40',
		'3_5mm': '6.26',
		'4_0mm': '7.10',
		'4_5mm': '7.93',
		'5_0mm': '8.75',
		'5_5mm': '9.56',
		'6_0mm': '',
	},
	{
		key: '29',
		diameter: '80',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '2.90',
		'2_0mm': '3.85',
		'2_5mm': '4.78',
		'3_0mm': '5.70',
		'3_5mm': '6.60',
		'4_0mm': '7.50',
		'4_5mm': '8.38',
		'5_0mm': '9.25',
		'5_5mm': '10.10',
		'6_0mm': '',
	},
	{
		key: '30',
		diameter: '83',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '3.01',
		'2_0mm': '3.99',
		'2_5mm': '4.96',
		'3_0mm': '5.92',
		'3_5mm': '6.86',
		'4_0mm': '7.79',
		'4_5mm': '8.74',
		'5_0mm': '9.61',
		'5_5mm': '10.51',
		'6_0mm': '',
	},
	{
		key: '31',
		diameter: '89',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '3.24',
		'2_0mm': '4.29',
		'2_5mm': '5.33',
		'3_0mm': '6.36',
		'3_5mm': '7.38',
		'4_0mm': '8.38',
		'4_5mm': '9.38',
		'5_0mm': '10.36',
		'5_5mm': '11.50',
		'6_0mm': '',
	},
	{
		key: '32',
		diameter: '96',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '3.49',
		'2_0mm': '4.64',
		'2_5mm': '5.76',
		'3_0mm': '6.88',
		'3_5mm': '7.98',
		'4_0mm': '9.07',
		'4_5mm': '10.15',
		'5_0mm': '11.22',
		'5_5mm': '12.27',
		'6_0mm': '',
	},
	{
		key: '33',
		diameter: '100',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '3.64',
		'2_0mm': '4.83',
		'2_5mm': '6.01',
		'3_0mm': '7.18',
		'3_5mm': '8.33',
		'4_0mm': '9.47',
		'4_5mm': '10.60',
		'5_0mm': '11.70',
		'5_5mm': '12.80',
		'6_0mm': '13.90',
	},
	{
		key: '34',
		diameter: '108',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '3.94',
		'2_0mm': '5.22',
		'2_5mm': '6.50',
		'3_0mm': '7.76',
		'3_5mm': '9.01',
		'4_0mm': '10.25',
		'4_5mm': '11.50',
		'5_0mm': '12.70',
		'5_5mm': '13.90',
		'6_0mm': '15.10',
	},
	{
		key: '35',
		diameter: '114',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '5.52',
		'2_5mm': '6.87',
		'3_0mm': '8.21',
		'3_5mm': '9.54',
		'4_0mm': '10.84',
		'4_5mm': '12.20',
		'5_0mm': '13.43',
		'5_5mm': '14.70',
		'6_0mm': '16.00',
	},
	{
		key: '36',
		diameter: '120',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '5.82',
		'2_5mm': '7.24',
		'3_0mm': '8.66',
		'3_5mm': '10.10',
		'4_0mm': '11.40',
		'4_5mm': '12.80',
		'5_0mm': '14.20',
		'5_5mm': '15.50',
		'6_0mm': '16.90',
	},
	{
		key: '37',
		diameter: '127',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '6.16',
		'2_5mm': '7.68',
		'3_0mm': '9.17',
		'3_5mm': '10.60',
		'4_0mm': '12.10',
		'4_5mm': '13.60',
		'5_0mm': '15.00',
		'5_5mm': '16.48',
		'6_0mm': '17.90',
	},
	{
		key: '38',
		diameter: '133',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '6.46',
		'2_5mm': '8.05',
		'3_0mm': '9.62',
		'3_5mm': '11.20',
		'4_0mm': '12.70',
		'4_5mm': '14.30',
		'5_0mm': '15.80',
		'5_5mm': '17.30',
		'6_0mm': '18.90',
	},
	{
		key: '39',
		diameter: '140',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '6.76',
		'2_5mm': '8.42',
		'3_0mm': '10.10',
		'3_5mm': '11.75',
		'4_0mm': '13.40',
		'4_5mm': '15.00',
		'5_0mm': '16.60',
		'5_5mm': '18.15',
		'6_0mm': '19.80',
	},
	{
		key: '40',
		diameter: '159',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '7.74',
		'2_5mm': '9.65',
		'3_0mm': '11.50',
		'3_5mm': '13.40',
		'4_0mm': '15.30',
		'4_5mm': '17.20',
		'5_0mm': '19.00',
		'5_5mm': '20.80',
		'6_0mm': '22.60',
	},
	{
		key: '41',
		diameter: '168',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '',
		'2_5mm': '10.20',
		'3_0mm': '12.21',
		'3_5mm': '14.20',
		'4_0mm': '16.18',
		'4_5mm': '18.14',
		'5_0mm': '20.09',
		'5_5mm': '22.04',
		'6_0mm': '23.97',
	},
	{
		key: '42',
		diameter: '193.7',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '18.70',
		'4_5mm': '20.99',
		'5_0mm': '23.30',
		'5_5mm': '25.52',
		'6_0mm': '27.80',
	},
	{
		key: '43',
		diameter: '219',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '21.20',
		'4_5mm': '23.80',
		'5_0mm': '26.40',
		'5_5mm': '28.95',
		'6_0mm': '31.50',
	},
	{
		key: '44',
		diameter: '244.5',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '26.40',
		'5_5mm': '32.41',
		'6_0mm': '35.30',
	},
	{
		key: '45',
		diameter: '273',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '33.00',
		'5_5mm': '36.28',
		'6_0mm': '39.50',
	},
	{
		key: '46',
		diameter: '324',
		'0_6mm': '',
		'0_7mm': '',
		'0_8mm': '',
		'1_0mm': '',
		'1_2mm': '',
		'1_5mm': '',
		'2_0mm': '',
		'2_5mm': '',
		'3_0mm': '',
		'3_5mm': '',
		'4_0mm': '',
		'4_5mm': '',
		'5_0mm': '39.30',
		'5_5mm': '43.20',
		'6_0mm': '47.05',
	},
];

const BoruGrubuTablo: React.FC = () => {
	const [isSmallScreen, setIsSmallScreen] = React.useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 1100);
		};

		handleResize();
		window.addEventListener('resize', handleResize);
	});
	return (
		<div>
			{isSmallScreen ? (
				<h1
					id="responsive-message"
					className="text-xl font-bold text-center p-4 mx-auto mt-12"
				>
					Tabloyu en verimli şekilde görmek için lütfen sitemizi masaüstünde tam
					ekran olarak açınız.
				</h1>
			) : (
				<Table
					columns={columns}
					dataSource={data}
					pagination={false}
					scroll={{ y: 600 }}
					bordered
					className="w-full"
				/>
			)}
		</div>
	);
};

export default BoruGrubuTablo;

import React from 'react';
import Careousel from '../components/careousel';
import AboutUs from '../components/AboutUs';
const Homepage: React.FC = () => {
  return (
    <div>
        <Careousel />
        <AboutUs />
    </div>
   
  )
}

export default Homepage;
import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/HomePage";
import About from "./pages/About";
import BoruGrubu from "./pages/BoruGrubu";
import BoruGrubuTablo from "./pages/BoruGrubuTablo";
import CatiGrubu from "./pages/CatiGrubu";
import HaddeGrubu from "./pages/HaddeGrubu";
import ProfilGrubu from "./pages/ProfilGrubu";
import SacGrubu from "./pages/SacGrubu";
import Navbar from "./components/navbar";
import FooterComponent from "./components/footer";
import "./index.css";
import HaddeKare from "./pages/HaddeComponents/kare";
import HaddeSilme from "./pages/HaddeComponents/silme";
import HaddeLama from "./pages/HaddeComponents/lama";
import HaddeKosebent from "./pages/HaddeComponents/kosebent";
import HaddeNpi from "./pages/HaddeComponents/npi";
import HaddeNpu from "./pages/HaddeComponents/npu";
import ProfilDikdortgen from "./pages/ProfilComponents/dikdortgen";
import ProfilKare from "./pages/ProfilComponents/kare";
import Contact from "./pages/Contact";

const App: React.FC = () => {
  return (
    <div className="App bg-gray w-full min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow bg-gray">
        <Routes>
          <Route element={<Homepage />} path="/" />
          <Route element={<BoruGrubu />} path="/boru" />
          <Route element={<BoruGrubuTablo />} path="/boru-tablo" />
          <Route element={<CatiGrubu />} path="/cati" />
          <Route element={<HaddeGrubu />} path="/hadde" />
          <Route element={<HaddeKare />} path="/hadde-kare" />
          <Route element={<HaddeSilme />} path="/hadde-silme" />
          <Route element={<HaddeLama />} path="/hadde-lama" />
          <Route element={<HaddeKosebent />} path="/hadde-kosebent" />
          <Route element={<HaddeNpi />} path="/hadde-npi" />
          <Route element={<HaddeNpu />} path="/hadde-npu" />
          <Route element={<ProfilGrubu />} path="/profil" />
          <Route element={<SacGrubu />} path="/sac" />
          <Route element={<About />} path="/about" />
          <Route element={<ProfilDikdortgen />} path="/profil-dikdortgen" />
          <Route element={<ProfilKare />} path="/profil-kare" />
          <Route element={<Contact />} path="/contact" />
        </Routes>
      </div>
      <FooterComponent />
    </div>
  );
};

export default App;

import React, { useEffect } from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

const columns = [
	{
		title: 'Ebat (mm) - Kalınlık (mm)',
		dataIndex: 'b1',
		key: 'b1',
	},
	{
		title: '1 Boy Ağırlık / 6 Metre (kg)',
		dataIndex: 'a1',
		key: 'a1',
	},
	{
		title: 'Ebat (mm) - Kalınlık (mm)',
		dataIndex: 'b2',
		key: 'b2',
	},
	{
		title: '1 Boy Ağırlık / 6 Metre (kg)',
		dataIndex: 'a2',
		key: 'a2',
	},
];

const data = [
	{
		b1: '10X20X1,50MM',
		a1: '4,21KG',
		s: '',
		b2: '50X100X3,00MM',
		a2: '42,12KG',
	},
	{
		b1: '10X30X1,50MM',
		a1: '5,61KG',
		s: '',
		b2: '50X100X4,00MM',
		a2: '56,16KG',
	},
	{
		b1: '10X30X2,00MM',
		a1: '7,48KG',
		s: '',
		b2: '50X100X5,00MM',
		a2: '70,20KG',
	},
	{
		b1: '15X25X1,50MM',
		a1: '5,61KG',
		s: '',
		b2: '50X100X6,00MM',
		a2: '77,82KG',
	},
	{
		b1: '15X25X2,00MM',
		a1: '7,48KG',
		s: '',
		b2: '60X80X1,50MM',
		a2: '19,65KG',
	},
	{
		b1: '20X30X1,50MM',
		a1: '7,02KG',
		s: '',
		b2: '60X80X2,00MM',
		a2: '26,20KG',
	},
	{
		b1: '20X30X2,00MM',
		a1: '9,36KG',
		s: '',
		b2: '60X80X3,00MM',
		a2: '39,31KG',
	},
	{
		b1: '20X30X3,00MM',
		a1: '12,41KG',
		s: '',
		b2: '60X80X4,00MM',
		a2: '52,41KG',
	},
	{
		b1: '20X40X1,50MM',
		a1: '8,42KG',
		s: '',
		b2: '60X80X5,00MM',
		a2: '65,52KG',
	},
	{
		b1: '20X40X2,00MM',
		a1: '10,56KG',
		s: '',
		b2: '60X100X2,00MM',
		a2: '29,95KG',
	},
	{
		b1: '20X40X3,00MM',
		a1: '16,84KG',
		s: '',
		b2: '60X100X3,00MM',
		a2: '44,92KG',
	},
	{
		b1: '30X40X1,50MM',
		a1: '9,82KG',
		s: '',
		b2: '60X100X4,00MM',
		a2: '56,16KG',
	},
	{
		b1: '30X40X2,00MM',
		a1: '13,10KG',
		s: '',
		b2: '60X100X5,00MM',
		a2: '74,88KG',
	},
	{
		b1: '30X40X3,00MM',
		a1: '19,65KG',
		s: '',
		b2: '60X100X6,00MM',
		a2: '83,46KG',
	},
	{
		b1: '30X50X1,50MM',
		a1: '11,23KG',
		s: '',
		b2: '80X100X3,00MM',
		a2: '50,54KG',
	},
	{
		b1: '30X50X2,00MM',
		a1: '14,96KG',
		s: '',
		b2: '80X100X4,00MM',
		a2: '67,39KG',
	},
	{
		b1: '30X50X3,00MM',
		a1: '22,46KG',
		s: '',
		b2: '80X100X5,00MM',
		a2: '84,24KG',
	},
	{
		b1: '30X50X4,00MM',
		a1: '33,69KG',
		s: '',
		b2: '80X100X6,00MM',
		a2: '94,73KG',
	},
	{
		b1: '30X50X5,00MM',
		a1: '32,89KG',
		s: '',
		b2: '80X120X2,00MM',
		a2: '37,44KG',
	},
	{
		b1: '40X60X1,50MM',
		a1: '14,04KG',
		s: '',
		b2: '80X120X3,00MM',
		a2: '56,16KG',
	},
	{
		b1: '40X60X2,00MM',
		a1: '18,72KG',
		s: '',
		b2: '80X120X4,00MM',
		a2: '74,88KG',
	},
	{
		b1: '40X60X3,00MM',
		a1: '28,08KG',
		s: '',
		b2: '80X120X5,00MM',
		a2: '93,60KG',
	},
	{
		b1: '40X60X4,00MM',
		a1: '37,44KG',
		s: '',
		b2: '80X120X6,00MM',
		a2: '106,01KG',
	},
	{
		b1: '40X60X5,00MM',
		a1: '46,80KG',
		s: '',
		b2: '80X120X8,00MM',
		a2: '138,34KG',
	},
	{
		b1: '40X80X1,50MM',
		a1: '16,84KG',
		s: '',
		b2: '100X150X3,00MM',
		a2: '70,20KG',
	},
	{
		b1: '40X80X2,00MM',
		a1: '22,46KG',
		s: '',
		b2: '100X150X4,00MM',
		a2: '93,60KG',
	},
	{
		b1: '40X80X3,00MM',
		a1: '33,69KG',
		s: '',
		b2: '100X150X5,00MM',
		a2: '117,00KG',
	},
	{
		b1: '40X80X4,00MM',
		a1: '44,92KG',
		s: '',
		b2: '100X150X6,00MM',
		a2: '134,21KG',
	},
	{
		b1: '40X80X5,00MM',
		a1: '56,16KG',
		s: '',
		b2: '100X150X8,00MM',
		a2: '175,93KG',
	},
	{
		b1: '40X100X1,50MM',
		a1: '19,65KG',
		s: '',
		b2: '100X200X3,00MM',
		a2: '84,24KG',
	},
	{
		b1: '40X100X2,00MM',
		a1: '26,20KG',
		s: '',
		b2: '100X200X4,00MM',
		a2: '112,32KG',
	},
	{
		b1: '40X100X3,00MM',
		a1: '39,31KG',
		s: '',
		b2: '100X200X5,00MM',
		a2: '140,40KG',
	},
	{
		b1: '40X100X4,00MM',
		a1: '52,41KG',
		s: '',
		b2: '100X200X6,00MM',
		a2: '162,40KG',
	},
	{
		b1: '40X100X5,00MM',
		a1: '65,52KG',
		s: '',
		b2: '100X200X8,00MM',
		a2: '213,53KG',
	},
	{
		b1: '50x90x1,50MM',
		a1: '19,65KG',
		s: '',
		b2: '150X250X4,00MM',
		a2: '149,76KG',
	},
	{
		b1: '50X90X2,00MM',
		a1: '26,20KG',
		s: '',
		b2: '150X250X5,00MM',
		a2: '163,80KG',
	},
	{
		b1: '50X90X3,00MM',
		a1: '39,31KG',
		s: '',
		b2: '150X250X6,00MM',
		a2: '218,79KG',
	},
	{
		b1: '50X90X4,00MM',
		a1: '52,41KG',
		s: '',
		b2: '150X250X8,00MM',
		a2: '288,71KG',
	},
	{
		b1: '50X90X5,00MM',
		a1: '65,52KG',
		s: '',
		b2: '200X300X5,00MM',
		a2: '234,00KG',
	},
	{
		b1: '50X100X1,50MM',
		a1: '21,06KG',
		s: '',
		b2: '200X300X6,00MM',
		a2: '275,18KG',
	},
	{
		b1: '50X100X2,00MM',
		a1: '28,08KG',
		s: '',
		b2: '200X300X8,00MM',
		a2: '363,90KG',
	},
];

const ProfilDikdortgen: React.FC = () => {
	const navigate = useNavigate();
	const [isSmallScreen, setIsSmallScreen] = React.useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 1100);
		};

		handleResize();
		window.addEventListener('resize', handleResize);
	});
	return (
		<div id="tablo-dikdortgen" className="mx-4 p-4 my-4">
			{isSmallScreen ? (
				<h1
					id="responsive-message"
					className="text-xl font-bold text-center p-4 mx-auto mt-12"
				>
					Tabloyu en verimli şekilde görmek için lütfen sitemizi masaüstünde tam
					ekran olarak açınız.
				</h1>
			) : (
				<>
					<div className="flex items-center justify-evenly my-4 flex-wrap">
						<Button
							type="primary"
							size="large"
							onClick={() => navigate('/profil-dikdortgen')}
							className="my-1 w-48"
						>
							Dikdörtgen Tablosu
						</Button>
						<Button
							type="dashed"
							size="large"
							onClick={() => navigate('/profil-kare')}
							className="my-1 w-48"
						>
							Kare Tablosu
						</Button>
					</div>
					<Table
						columns={columns}
						dataSource={data}
						bordered
						pagination={false}
						title={() => {
							return (
								<h1 className="text-xl font-bold text-center">
									Dikdörtgen Profil Ağırlık Tablosu
								</h1>
							);
						}}
					/>
				</>
			)}
		</div>
	);
};

export default ProfilDikdortgen;

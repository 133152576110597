import React from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

const columns = [
	{
		title: 'Boyut (mm)',
		dataIndex: 'boyut',
		key: 'boyut',
	},
	{
		title: 'Ağırlık (kg/m)',
		dataIndex: 'agirlik',
		key: 'agirlik',
	},
];

const dataKosebent = [
	{
		key: '1',
		boyut: '20x20x2.0',
		agirlik: '0.5966',
	},
	{
		key: '2',
		boyut: '25x25x2.5',
		agirlik: '0.9322',
	},
	{
		key: '3',
		boyut: '30x30x3.0',
		agirlik: '1.3424',
	},
	{
		key: '4',
		boyut: '40x40x4.0',
		agirlik: '2.3864',
	},
	{
		key: '5',
		boyut: '50x50x5,0',
		agirlik: '3.7288',
	},
	{
		key: '6',
		boyut: '60x60x6.0',
		agirlik: '5.3694',
	},
	{
		key: '7',
		boyut: '70x70x7.0',
		agirlik: '7.3084',
	},
	{
		key: '8',
		boyut: '80x80x8.0',
		agirlik: '9.5456',
	},
	{
		key: '9',
		boyut: '100x100x10',
		agirlik: '14.915',
	},
	{
		key: '10',
		boyut: '120x120x12',
		agirlik: '21.4776',
	},
	{
		key: '11',
		boyut: '150x150x15',
		agirlik: '33.5588',
	},
	{
		key: '12',
		boyut: '200x200x20',
		agirlik: '59.66',
	},
];

const HaddeKosebent: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div id="tablo-kare" className="mx-4 p-4 my-4">
			<h1 className="text-xl font-bold text-center">
				Hadde grubu agirlik tablolari
			</h1>

			<div className="flex items-center justify-evenly my-4 flex-wrap">
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-kare')}
					className="my-1 w-40"
				>
					Kare Tablosu
				</Button>
				<Button
					type="primary"
					size="large"
					onClick={() => navigate('/hadde-kosebent')}
					className="my-1 w-40"
				>
					Kosebent Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-lama')}
					className="my-1 w-40"
				>
					Lama Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npi')}
					className="my-1 w-40"
				>
					NPI Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npu')}
					className="my-1 w-40"
				>
					NPU Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-silme')}
					className="my-1 w-40"
				>
					Silme Tablosu
				</Button>
			</div>
			<Table
				columns={columns}
				dataSource={dataKosebent}
				bordered
				pagination={false}
				title={() => {
					return (
						<h1 className="text-xl font-bold text-center">Köşebent Tablosu</h1>
					);
				}}
			/>
		</div>
	);
};

export default HaddeKosebent;

import React from 'react';
import { Button, Card } from 'antd';
import KareProfilImage from '../images/kare-demir.jpg';
import DikdortgenProfilImage from '../images/dikdortgen-profil.jpg';
import ProfilDetay from '../images/profil-grubu.jpg';
import '../index.css';
import { useNavigate } from 'react-router-dom';

const ProfilGrubu: React.FC = () => {
	const { Meta } = Card;
	const navigate = useNavigate();
	const paragraphStyle = 'my-4 text-xl indent-8';
	const subTitleStyle = 'text-2xl font-bold text-center mb-4';
	const cardStyle = 'w-full md:w-2/5 lg:w-1/5 mb-12';
	const titleStyle = 'text-2xl font-bold text-center mb-8';
	const buttonStyle = 'mt-3 flex flex-col items-center justify-center w-full';

	const KareProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Kare profiller, dört kenarı eşit uzunlukta olan ve genellikle yapı ve
				makine sanayisinde kullanılan çelik profillerdir. Yüksek mukavemeti ve
				estetik görünümü ile bilinirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Dayanıklı ve sağlam yapısı ile ağır yük
					taşıma kapasitesine sahiptir.
				</li>
				<li>
					<b>Estetik Görünüm:</b> Düzgün ve simetrik şekli sayesinde estetik bir
					görünüm sunar.
				</li>
				<li>
					<b>Çok Yönlü Kullanım:</b> Çeşitli uygulama alanlarında
					kullanılabilir.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İnşaat sektörü</li>
				<li>Makine imalatı</li>
				<li>Raf sistemleri</li>
				<li>Mobilya üretimi</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 10 mm x 10 mm'den 300 mm x 300 mm'ye kadar
				</li>
				<li>
					<b>Kalınlık:</b> 1 mm - 20 mm
				</li>
				<li>
					<b>Uzunluk:</b> İstenilen uzunlukta kesilebilir
				</li>
			</ul>

			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/profil-kare')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);
	const DikdörtgenProfilDesc = (
		<div>
			<h1 className="text-xl mb-4 ">Genel Bilgiler</h1>
			<p className="mb-4">
				Genel Bilgiler Dikdörtgen profiller, iki uzun kenarı ve iki kısa kenarı
				olan çelik profillerdir. Yüksek mukavemet ve esneklik sunar, bu yüzden
				çeşitli endüstriyel uygulamalarda tercih edilirler.
			</p>
			<h2 className="mb-4 text-xl">Özellikler ve Avantajlar</h2>
			<ul className="mb-4">
				<li>
					<b>Yüksek Mukavemet:</b> Dayanıklı yapısı sayesinde ağır yükleri
					taşıyabilir.
				</li>
				<li>
					<b>Estetik Görünüm:</b> Düzgün kenarları ve yüzeyi sayesinde estetik
					bir görünüme sahiptir.
				</li>
				<li>
					<b>Esneklik:</b> Farklı boyut ve kalınlıklarda üretilebilir, çeşitli
					projelere uyum sağlar.
				</li>
			</ul>
			<h2 className="mb-4 text-xl">Uygulama Alanları</h2>
			<ul className="mb-4">
				<li>İnşaat sektörü</li>
				<li>Makine imalatı</li>
				<li>Taşıma sistemleri</li>
				<li>Tarım makineleri</li>
			</ul>
			<h2 className="mb-4 text-xl">Teknik Özellikler</h2>
			<ul className="list-disc">
				<li>
					<b>Boyutlar:</b> 20 mm x 10 mm'den 400 mm x 200 mm'ye kadar
				</li>
				<li>
					<b>Kalınlık:</b> 1 mm - 20 mm
				</li>
				<li>
					<b>Uzunluk:</b> İstenilen uzunlukta kesilebilir
				</li>
			</ul>

			<Button
				type="primary"
				className={buttonStyle}
				onClick={() => navigate('/profil-dikdortgen')}
			>
				Ağırlık Tablosu
			</Button>
		</div>
	);

	return (
		<div className="m-8">
			<h1 className="text-3xl font-bold text-center mb-4">Profil Grubu</h1>
			<section className="flex flex-row flex-nowrap my-4">
				<div
					id="profil-paragraphs"
					className="mx-auto w-auto p-4 flex flex-col-reverse md:flex-row lg:flex-row justify-center items-center"
				>
					<div>
						<p className={paragraphStyle}>
							Profil grubu ürünlerimiz, İzmir'deki demir çelik sektöründe geniş
							bir kullanım alanına sahip olan yüksek kaliteli malzemelerden
							oluşur. Firmamız, kare profiller ve dikdörtgen profiller gibi
							çeşitli profil türlerini müşterilerimize sunmaktadır. Her biri
							farklı özelliklere sahip olan bu ürünler, inşaat, mobilya, makine
							imalatı ve çeşitli endüstriyel uygulamalarda kullanılmak üzere
							tasarlanmıştır.
						</p>
						<p className={paragraphStyle}>
							Profil grubu ürünlerimiz, yüksek mukavemet, dayanıklılık ve
							estetik görünüm sunarak projelerinizin ihtiyaçlarına en uygun
							çözümleri sağlar. Ürünlerimiz hakkında daha fazla bilgi edinmek ve
							projeleriniz için en doğru profil türünü seçmek için ilgili
							sayfaları inceleyebilir veya bizimle iletişime geçebilirsiniz.
						</p>
					</div>
					<img
						src={ProfilDetay}
						className="w-full md:w-1/2 lg:w-2/3 h-full md:h-1/2 lg:h-2/3 my-auto rounded-md shadow-xl"
						alt="Profil Grubu"
					/>
				</div>
			</section>
			<h2 className={titleStyle}>Profil Grubu Ürünleri</h2>
			<div
				id="profil-cards"
				className="flex flex-row flex-wrap justify-around gap-4 my-4"
			>
				<Card
					className={cardStyle}
					cover={<img alt="Kare Profil" src={KareProfilImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Kare Profiller</h2>}
						description={KareProfilDesc}
					/>
				</Card>
				<Card
					className={cardStyle}
					cover={<img alt="Dikdörtgen Profiller" src={DikdortgenProfilImage} />}
				>
					<Meta
						title={<h2 className={subTitleStyle}>Dikdörtgen Profiller</h2>}
						description={DikdörtgenProfilDesc}
					/>
				</Card>
			</div>
		</div>
	);
};

export default ProfilGrubu;

import React from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

const columns = [
	{
		title: 'Boyut (mm)',
		dataIndex: 'boyut',
		key: 'boyut',
	},
	{
		title: 'Ağırlık (kg/m)',
		dataIndex: 'agirlik',
		key: 'agirlik',
	},
];

const dataNpu = [
	{
		key: '1',
		boyut: '40x20',
		agirlik: '2.87',
	},
	{
		key: '2',
		boyut: '65x42',
		agirlik: '7.09',
	},
	{
		key: '3',
		boyut: '80x45',
		agirlik: '8.64',
	},
	{
		key: '4',
		boyut: '100x50',
		agirlik: '10.6',
	},
	{
		key: '5',
		boyut: '120x55',
		agirlik: '13.4',
	},
	{
		key: '6',
		boyut: '140x60',
		agirlik: '16',
	},
	{
		key: '7',
		boyut: '160x65',
		agirlik: '18.8',
	},
	{
		key: '8',
		boyut: '180x70',
		agirlik: '22',
	},
	{
		key: '9',
		boyut: '200x75',
		agirlik: '25.3',
	},
	{
		key: '10',
		boyut: '220x80',
		agirlik: '29.4',
	},
	{
		key: '11',
		boyut: '240x85',
		agirlik: '33.2',
	},
	{
		key: '12',
		boyut: '260x90',
		agirlik: '37.9',
	},
];

const HaddeNpu: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div id="tablo-kare" className="mx-4 p-4 my-4">
			<h1 className="text-xl font-bold text-center">
				Hadde grubu agirlik tablolari
			</h1>

			<div className="flex items-center justify-evenly my-4 flex-wrap">
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-kare')}
					className="my-1 w-40"
				>
					Kare Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-kosebent')}
					className="my-1 w-40"
				>
					Kosebent Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-lama')}
					className="my-1 w-40"
				>
					Lama Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-npi')}
					className="my-1 w-40"
				>
					NPI Tablosu
				</Button>
				<Button
					type="primary"
					size="large"
					onClick={() => navigate('/hadde-npu')}
					className="my-1 w-40"
				>
					NPU Tablosu
				</Button>
				<Button
					type="dashed"
					size="large"
					onClick={() => navigate('/hadde-silme')}
					className="my-1 w-40"
				>
					Silme Tablosu
				</Button>
			</div>
			<Table
				columns={columns}
				dataSource={dataNpu}
				bordered
				pagination={false}
				title={() => {
					return <h1 className="text-xl font-bold text-center">NPU Tablosu</h1>;
				}}
			/>
		</div>
	);
};

export default HaddeNpu;
